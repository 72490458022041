import createAxiosInstance from './axiosConfig';
import { AcaoPropostaEnum } from '../shared/constantes/acaoPropostaEnum';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/propostaEsteiraController');

export default class PropostaEsteiraService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarPropostaAtividadePorIdProposta(idProposta: string) {
    return await this.axios.get(`/listarPropostaAtividadePorIdProposta?idProposta=${idProposta}`);
  };

  async incluirPropostaEsteira(idProposta: string, acaoPropostaEnum: AcaoPropostaEnum, idMotivo: string | null) {
    return await this.axios.get(`/incluirPropostaEsteira?idProposta=${idProposta}&acaoPropostaEnum=${acaoPropostaEnum.toLocaleUpperCase()}&idMotivo=${idMotivo}`);
  };

  async incluirPropostaEsteiraComExecucao(idProposta: string, acaoPropostaEnum: AcaoPropostaEnum, processo: string, idMotivo: string | null) {
    return await this.axios.get(`/incluirPropostaEsteiraComExecucao?idProposta=${idProposta}&acaoPropostaEnum=${acaoPropostaEnum.toLocaleUpperCase()}&execucao=${processo}&idMotivo=${idMotivo}`);
  };

  async retornarFluxo(idProposta: string, retornoEtapa: string) {
    return await this.axios.get(`/retornarFluxo?idProposta=${idProposta}&idAtividadeRetorno=${retornoEtapa}`);
  };

  async incluirExcluirAdeManual(idProposta: string, ade: string, incluir: boolean, valorParcela: number | undefined) {
    return await this.axios.get(`/incluirExcluirAdeManual?idProposta=${idProposta}&ade=${ade}&incluir=${incluir}&valorParcela=${valorParcela}`);
  };

}