import 'bootstrap/dist/css/bootstrap.css';
import { cnpj as cnpjValidacao } from 'cpf-cnpj-validator';
import { ChangeEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import { EnderecoDTO } from '../../../shared/dto/enderecoDTO';
import { PromotoraDTO } from '../../../shared/dto/promotoraDTO';
import { Matriz } from '../../../shared/model/matriz';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import MatrizService from '../../../services/matrizService';
import PromotoraService from '../../../services/promotoraService';
import ViaCepService from '../../../services/viaCepService';
import CustomAlert from '../../../shared/customAlert';

function PromotoraInclusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [matriz, setMatriz] = useState('');
  const [matrizes, setMatrizes] = useState<Matriz[]>([]);
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [emails, setEmails] = useState('');
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');
  const [codExterno, setCodExterno] = useState('');
  const [gerenteResponsavel, setGerenteResponsavel] = useState('');
  const [valorMeta, setValorMeta] = useState<number | string>(0);

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');

  const [loading, setLoading] = useState(false);

  const matrizService: MatrizService = new MatrizService();
  const promotoraService: PromotoraService = new PromotoraService();
  const viaCepService: ViaCepService = new ViaCepService();

  const listarMatriz = async () => {
    try {
      const response = await matrizService.listarTodasMatrizesAtivas();
      if (tipo === 'inclusao') {
        setMatriz(response.data[0].id.toString());
      }
      setMatrizes(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const listarPromotoraPorId = async () => {
    try {
      setLoading(true);
      const response = await promotoraService.listarPromotoraPorId(id);
      const jsonPromotora = response.data;

      setMatriz(jsonPromotora.idMatriz.toString());
      setNome(jsonPromotora.nome);
      setCnpj(jsonPromotora.cnpj);
      setEmails(jsonPromotora.email);
      setTelefoneComercial(jsonPromotora.foneComercial);
      setTelefoneCelular(jsonPromotora.foneCelular);
      setCodExterno(jsonPromotora.codExterno);
      setGerenteResponsavel(jsonPromotora.gerenteResponsavel);
      setValorMeta(jsonPromotora.valorMeta);
      setCep(jsonPromotora.cep);
      setLogradouro(jsonPromotora.endereco);
      setNumero(jsonPromotora.numeroEndereco);
      setComplemento(jsonPromotora.complemento);
      setBairro(jsonPromotora.bairro);
      setLocalidade(jsonPromotora.cidade);
      setUf(jsonPromotora.uf);

    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  const limparCampos = () => {
    setNome('');
    setCnpj('');
    setEmails('');
    setTelefoneComercial('');
    setTelefoneCelular('');
    setCodExterno('');
    setGerenteResponsavel('');
    setValorMeta(0);
    setCep('');
    setLogradouro('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setLocalidade('');
    setUf('');
  }

  const handleSubmit = async (event: any) => {
    if (!cnpjValidacao.isValid(cnpj)) {
      setAlert({ message: 'CNPJ inválido', type: 'warning' });
      return;
    } else {
      event.preventDefault();
      setLoading(true);
      let endereco: EnderecoDTO = new EnderecoDTO();
      endereco.cep = cep;
      endereco.logradouro = logradouro;
      endereco.numero = numero;
      endereco.complemento = complemento;
      endereco.bairro = bairro;
      endereco.uf = uf;
      endereco.localidade = localidade;
      let promotora: PromotoraDTO = new PromotoraDTO();
      promotora.matriz = matriz;
      promotora.id = id!;
      promotora.nome = nome;
      promotora.cnpj = cnpj;
      promotora.email = emails;
      promotora.enderecoDTO = endereco;
      promotora.telefoneComercial = telefoneComercial;
      promotora.telefoneCelular = telefoneCelular;
      promotora.codExterno = codExterno;
      promotora.gerenteResponsavel = gerenteResponsavel;
      promotora.valorMeta = valorMeta;

      try {
        await promotoraService.incluirAlterarPromotora(promotora);

        setLoading(false);
        setAlert({ message: 'Promotora salva com sucesso!', type: 'success' });
      } catch (err: any) {
        setLoading(false);
        setAlert({ message: err.response.data, type: 'error' });
        console.error(err);
      }
    };
  }

  const handleValorMetaChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const valorDigitado = e.target.value.replace(/[^\d.,]/g, '');
    setValorMeta(valorDigitado);
  };

  const viaCep = async (cep: string) => {
    if (cep !== undefined && cep !== '' && cep.length === 10) {
      console.log(cep)
      setLoading(true);
      const cepWithoutMask = cep.replace(/\D/g, '');
      try {
        const response = await viaCepService.viaCep(cepWithoutMask);

        const jsonViaCep = response.data;
        setLogradouro(jsonViaCep.logradouro);
        setLocalidade(jsonViaCep.localidade);
        setUf(jsonViaCep.uf);
        setBairro(jsonViaCep.bairro);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    listarMatriz();
    if (tipo === 'alteracao') {
      listarPromotoraPorId();
    }
  }, [])

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=> { setAlert(null); if (alert.type === 'success') {window.history.back();} }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Origens &gt; Promotora &gt; Inclusão</h5> : (
                <h5>Origens &gt; Promotora &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            <div className="col-md">
              <label>Matriz <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={matriz} value={matriz} onChange={(e) => setMatriz(e.target.value)}>
                {matrizes.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md">
              <label>Gerente Responsável</label>
              <input type="text" className="form-control" value={gerenteResponsavel} onChange={(e) => setGerenteResponsavel(e.target.value)} />
            </div>
          </div>

          <div className="row">

            <div className="col-xl">
              <label>Razão Social <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>

            <div className="col-xl">
              <label>CNPJ <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
            </div>

          </div>

          <div className="row">
            <div className="col-xl" style={{ marginTop: '5px' }}>
              <label>Lista de E-mails (separados por ';')<span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={emails} onChange={(e) => setEmails(e.target.value)} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {loading ? <Spinner loading={loading} /> : false}
            <div className="col-md-2">
              <label>CEP <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99.999-999" type="text" className="form-control" required
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                onBlur={(e) => viaCep(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label>Endereço <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label>Número <span style={{ color: 'red' }}>*</span></label>
              <input type="number" className="form-control" required value={numero} onChange={(e) => setNumero(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label>Bairro <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={bairro} onChange={(e) => setBairro(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Complemento</label>
              <input type="text" className="form-control" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Cidade <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={localidade} onChange={(e) => setLocalidade(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Estado <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="aa" type="text" required className="form-control" value={uf} onChange={(e) => setUf(e.target.value.toUpperCase())} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            <div className="col-md">
              <label className="form-label">Telefone Comercial <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)9999-9999" type="text" className="form-control" value={telefoneComercial} onChange={(e) => setTelefoneComercial(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-md">
              <label className="form-label">Telefone Celular <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)99999-9999" type="text" className="form-control" value={telefoneCelular} onChange={(e) => setTelefoneCelular(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label className="form-label">Código Externo</label>
              <div className="row">
                <div className="col-md">
                  <input type="text" className="form-control" value={codExterno} onChange={(e) => setCodExterno(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="col-md">
              <label className="form-label">Valor Meta <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <input
                    type="text"
                    required
                    className="form-control"
                    value={valorMeta}
                    onChange={handleValorMetaChange}
                  />
                </div>
              </div>
            </div>


          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Promotora' : 'Salvar Promotora'}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default PromotoraInclusaoAlteracao;