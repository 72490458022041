import { Cliente } from "../model/Cliente";
import { PropostaEsteira } from "../model/propostaEsteira";
import { ResultadoSimulacaoPropostaDTO } from "./resultadoSimulacaoPropostaDTO";

export class PropostaDTO {
  public id!: number;
  
  public promotora!: string;
  public gerente!: string;
  public operador!: string;
  public pontoVenda!: string;

  public produto!: string;
  public tipoProposta!: string;
  public empregador!: string;
  public orgao!: string;
  public cliente!: Cliente;
  public idCliente!: number;
  public cpfCliente!: string;
  public numeroBeneficiarioCliente!: string;
  public nomeCliente!: string;
  public dtNascimentoCliente!: Date;
  public tipoConta!: string;
  public banco!: string;
  public agencia!: string;
  public conta!: string;
  public contaDv!: string;
  public valorRMC!: number;
  public valorLimite!: number;
  public limiteCompra!: number;
  public limiteSaque!: number;
  public tarifaEmissao!: number;
  public valorDespesas!: number;
  public valorTC!: number;
  public anuidade!: number;

  public taxaClAm!: number;
  public taxaClAa!: number;
  public taxaCetAm!: number;
  public taxaCetAa!: number;
  public valorSolicitado!: number;
  public valorIof!: number;
  public valorLiquido!: number;
  public valorParcela!: number;
  public valorBruto!: number;
  public valorPrincipal!: number;
  public usuario!: string;
  
  public simulacaoProposta!: ResultadoSimulacaoPropostaDTO;
  public prazo!: number;
  public dtPrimeiroVencimento!: Date;
  public dtUltimoVencimento!: Date;
  public dtAlteracao!: Date;
  public dataAlteracao!: string;
  public codigoTabela!: string;
	public tabela!: string;

  public financiamento!: string;

  public statusProposta!: string;
  public atividadeAtual!: string;
  public dtMovimentacao!: Date;
  public processoEsteira!: string;
  public pmt!: number;
  public numeroContrato!: string;

  public idFormalizacao!: string;
  public numeroContratoExterno!: string;
  public adeAverbacao!: string;
  public idEsteira!: string;

  public selected!: boolean;
  public dataCadastro!: Date;
  public isFinalizada!: boolean;
  public atividadesAnteriores!: Array<PropostaEsteira>

}