import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import { Button } from 'react-bootstrap';
import { Comunicado } from '../../shared/model/comunicado';
import { TipoComunicado } from '../../shared/model/tipoComunicado';
import InputMask from 'react-input-mask';
import { format, parse } from 'date-fns';
import { Promotora } from '../../shared/model/promotora';
import PromotoraService from '../../services/promotoraService';
import ComunicadoService from '../../services/comunicadoService';
import CustomAlert from '../../shared/customAlert';

function ComunicadosInclusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [loading, setLoading] = useState(false);
  const [tiposComunicado, setTiposComunicado] = useState<TipoComunicado[]>([]);
  const [tipoComunicado, setTipoComunicado] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [dtInicio, setDtInicio] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtFim, setDtFim] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [descricao, setDescricao] = useState('');

  const [availablePromotoras, setAvailablePromotoras] = useState<Promotora[]>([]);
  const [selectedPromotoras, setSelectedPromotoras] = useState<Promotora[]>([]);
  const [selectedToMovePromotoras, setSelectedToMovePromotoras] = useState<Promotora[]>([]);
  const [selectedFromSelectedPromotoras, setSelectedFromSelectedPromotoras] = useState<Promotora[]>([]);

  const [selectPromotora, setSelectPromotora] = useState(false);
  const [selectSupervisor, setSelectSupervisor] = useState(false);
  const [selectPontoVenda, setSelectPontoVenda] = useState(false);

  const [arquivo, setArquivo] = useState<File | null>(null);
  const [arquivoBase64, setArquivoBase64] = useState<string>('');
  const [nomeArquivo, setNomeArquivo] = useState<string>('');

  const promotoraService: PromotoraService = new PromotoraService();
  const comunicadoService: ComunicadoService = new ComunicadoService();

  const listarTipoComunicados = async () => {
    setLoading(true);
    try {
      const response = await comunicadoService.listarTodosTipoComunicadoAtivo();
      setTiposComunicado(response.data);
      if (tipo === 'inclusao') {
        setTipoComunicado(response.data[0].id?.toString()!);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const listarComunicadoPorId = async () => {
    setLoading(true);
    try {
      const response = await comunicadoService.listarComunicadoPorId(id);
      const json = response.data;

      setNome(json.nomeComunicado);
      setTipoComunicado(json.idTipoComunicado?.toString() || '');
      setDtInicio(format(new Date(json.dtInicio), 'dd/MM/yyyy'));
      setDtFim(format(new Date(json.dtFim), 'dd/MM/yyyy'));
      setDescricao(json.descricao);
      setSelectPromotora(json.exibirPara.includes('P'));
      setSelectSupervisor(json.exibirPara.includes('S'));
      setSelectPontoVenda(json.exibirPara.includes('L'));
      setCodigo(json.codigo);
      setSelectedPromotoras(json.promotorasList);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const listarPromotoras = async () => {
    setLoading(true);
    try {
      const response = await promotoraService.listarTodasPromotorasAtivas();
      const allPromotoras = response.data;

      if (tipo === 'alteracao') {
        const available = allPromotoras.filter((p: { id: number | null; }) => !selectedPromotoras.some(s => s.id === p.id));
        setAvailablePromotoras(available);
      } else {
        setAvailablePromotoras(allPromotoras);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const handleSubmit = async () => {
    if (selectedPromotoras.length <= 0) return setAlert({ message: 'Selecione ao menos uma Promotora', type: 'warning' });
    if (nome === '' || dtInicio === '' || dtFim === '' || tipoComunicado === '') return setAlert({ message: 'Preencha todos os campos obrigatórios', type: 'warning' });

    let comunicado: Comunicado = new Comunicado();
    comunicado.id = (id !== '0' ? parseInt(id!) : null);
    comunicado.codigo = codigo;
    comunicado.nomeComunicado = nome;
    comunicado.idTipoComunicado = parseInt(tipoComunicado);
    comunicado.dtInicio = parse(dtInicio, "dd/MM/yyyy", new Date());
    comunicado.dtFim = parse(dtFim, "dd/MM/yyyy", new Date());
    comunicado.promotorasList = selectedPromotoras;
    comunicado.descricao = descricao;
    comunicado.exibirPara = '';
    if (selectPromotora)
      comunicado.exibirPara += 'P';
    if (selectSupervisor)
      comunicado.exibirPara += 'S';
    if (selectPontoVenda)
      comunicado.exibirPara += 'L';

    comunicado.arquivoBase64 = arquivoBase64.split(',')[1];
    comunicado.nomeArquivo = nomeArquivo;

    try {
      const response = await comunicadoService.inclusaoAlteracaoComunicado(comunicado);

      setLoading(false);
      setAlert({ message: response.data, type: 'success' });
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    }
  };



  const handleClickUpload = (event: any) => {
    if (!event.target.files[0].name.endsWith('.png') && !event.target.files[0].name.endsWith('.jpeg') && !event.target.files[0].name.endsWith('.jpg')) {
      return setAlert({ message: 'O arquivo deve estar no formato .png, .jpeg ou .jpg', type: 'warning' });
    } else {
      setArquivo(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target?.result === 'string') {
          setArquivoBase64(e.target.result);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
      setNomeArquivo(event.target.files[0].name);
    }
  };

  useEffect(() => {
    listarTipoComunicados();
    if (tipo === 'alteracao' && id) {
      listarComunicadoPorId();
    } else {
      listarPromotoras();
    }
  }, [tipo, id]);

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarPromotoras();
    }
  }, [selectedPromotoras]);

  const moveToSelectedPromotoras = () => {
    const newSelected = [...selectedPromotoras, ...selectedToMovePromotoras];
    const newAvailable = availablePromotoras.filter(
      (promotora) => !selectedToMovePromotoras.includes(promotora)
    );

    setAvailablePromotoras(newAvailable);
    setSelectedPromotoras(newSelected);
    setSelectedToMovePromotoras([]);
  };

  const moveToAvailablePromotoras = () => {
    const newAvailable = [...availablePromotoras, ...selectedFromSelectedPromotoras];
    const newSelected = selectedPromotoras.filter(
      (promotora) => !selectedFromSelectedPromotoras.includes(promotora)
    );

    setAvailablePromotoras(newAvailable);
    setSelectedPromotoras(newSelected);
    setSelectedFromSelectedPromotoras([]);
  };

  const moveAllToSelectedPromotoras = () => {
    const newSelected = [...selectedPromotoras, ...availablePromotoras];
    setSelectedPromotoras(newSelected);
    setAvailablePromotoras([]);
  };

  const moveAllToAvailablePromotoras = () => {
    const newAvailable = [...availablePromotoras, ...selectedPromotoras];
    setAvailablePromotoras(newAvailable);
    setSelectedPromotoras([]);
  };

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=> { setAlert(null); if (alert.type === 'success') {window.history.back();} }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Comunicados &gt; Inclusão</h5> : (
                <h5>Comunicados &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            {tipo === 'alteracao' ? (
              <div className="col-md-1">
                <label>Código</label>
                <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
              </div>
            ) : null}
            <div className="col-md-4">
              <label>Nome Do Comunicado <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label>Tipo de Comunicado <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" value={tipoComunicado} onChange={(e) => setTipoComunicado(e.target.value)}>
                {tiposComunicado.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.descricao}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label>Data Início <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99/99/9999" className="form-control" type="text"
                value={dtInicio} onChange={(e) => setDtInicio((e.target.value).replace(/_/g, ""))}
              />
            </div>
            <div className="col-md-2">
              <label>Data Fim <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99/99/9999" className="form-control" type="text"
                value={dtFim} onChange={(e) => setDtFim((e.target.value).replace(/_/g, ""))}
              />
            </div>
          </div>

          <div style={{ display: 'flex', marginTop: '10px' }}>
            <div>
              <h6>Promotoras Disponíveis</h6>
              <select
                multiple
                value={selectedToMovePromotoras.map((p) => p.id!.toString())}
                onChange={(e) => {
                  const options = e.target.options;
                  const selected = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                      const promotora = availablePromotoras.find(p => p.id!.toString() === options[i].value);
                      if (promotora) {
                        selected.push(promotora);
                      }
                    }
                  }
                  setSelectedToMovePromotoras(selected);
                }}
                style={{ width: '575px', height: '180px' }}
              >
                {availablePromotoras.map((promotora) => (
                  <option key={promotora.id} value={promotora.id!.toString()}>
                    {promotora.id} - {promotora.nome!}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <button onClick={moveToSelectedPromotoras} style={{ margin: '5px' }}>{'>'}</button>
              <button onClick={moveAllToSelectedPromotoras} style={{ margin: '5px' }}>{'>>'}</button>
              <button onClick={moveToAvailablePromotoras} style={{ margin: '5px' }}>{'<'}</button>
              <button onClick={moveAllToAvailablePromotoras} style={{ margin: '5px' }}>{'<<'}</button>
            </div>
            <div>
              <h6>Promotoras Selecionadas</h6>
              <select
                multiple
                value={selectedFromSelectedPromotoras.map((p) => p.id!.toString())}
                onChange={(e) => {
                  const options = e.target.options;
                  const selected = [];
                  for (let i = 0; i < options.length; i++) {
                    if (options[i].selected) {
                      const promotora = selectedPromotoras.find(p => p.id!.toString() === options[i].value);
                      if (promotora) {
                        selected.push(promotora);
                      }
                    }
                  }
                  setSelectedFromSelectedPromotoras(selected);
                }}
                style={{ width: '575px', height: '180px' }}
              >
                {selectedPromotoras.map((promotora) => (
                  <option key={promotora.id} value={promotora.id!.toString()}>
                    {promotora.id} - {promotora.nome!}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label>Descrição</label>
              <input type="text" className="form-control" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px' }}>
            <div className="col">
              <label>Visualizar Comunicado</label>
              <div className="form-group form-control">
                <label style={{ marginRight: '10px' }}>
                  <input style={{ marginRight: '7px' }} type="checkbox" checked={selectPromotora} onChange={(e) => setSelectPromotora(e.target.checked)} />
                  Promotora
                </label>
                <label style={{ marginRight: '10px' }}>
                  <input style={{ marginRight: '7px' }} type="checkbox" checked={selectSupervisor} onChange={(e) => setSelectSupervisor(e.target.checked)} />
                  Supervisor
                </label>
                <label style={{ marginRight: '10px' }}>
                  <input style={{ marginRight: '7px' }} type="checkbox" checked={selectPontoVenda} onChange={(e) => setSelectPontoVenda(e.target.checked)} />
                  Ponto de Venda
                </label>
              </div>
            </div>
          </div>

          <div className='row' style={{ marginTop: '10px' }}>
            <div className="col-md-6">
              <div className="form-group">
                <span>Arquivo (formato .png, .jpeg ou .jpg)</span>
                <br />
                <input
                  style={{ backgroundColor: '#24393A', borderColor: '#24393A', border: 'none', color: 'White', width: '90%' }}
                  type="file" className="form-control-file btn btn-info"
                  onChange={(e) => handleClickUpload(e)}
                />
              </div>
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" onClick={handleSubmit}>{tipo !== 'inclusao' ? 'Alterar Comunicado' : 'Salvar Comunicado'}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComunicadosInclusaoAlteracao;