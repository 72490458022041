import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { Produto } from '../../../shared/model/produto';
import { ButtonAlterar } from '../../../shared/buttons';
import ProdutoService from '../../../services/produtoService';

function ProdutoComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState<Produto[]>([]);

  const produtoService: ProdutoService = new ProdutoService();

  const listarTodosProdutos = async () => {
    try {
      setLoading(true);
      const responseList = await produtoService.listarTodosProdutos();
      setProdutos(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarTodosProdutos();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Produto</h5>
        <Button
          onClick={() => navigate('/parametros-produto/inclusao', { state: { id: null } })}
          style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
        >
          + Novo Produto
        </Button>
      </div>
      {loading ? <Spinner loading={loading} /> : (
        <div className="table-responsive">
          <div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table">Código</th>
                  <th scope="col" id="App-header-table">Nome</th>
                  <th scope="col" id="App-header-table">Tipo Produto</th>
                  <th scope="col" id="App-header-table">Código Externo</th>
                  <th scope="col" id="App-header-table">Esteira</th>
                  <th scope="col" id="App-header-table">Alteração</th>
                </tr>
              </thead>
              <tbody>
                {produtos.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.nome}</td>
                    <td>{item.tipoProduto}</td>
                    <td>{item.codigoMotor}</td>
                    <td>{item.esteira.id} - {item.esteira.nomeEsteira}</td>
                    <td>
                      <Link to={`/parametros-produto/alteracao`} state={{ id: item.id }}>
                        <ButtonAlterar />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProdutoComponent;