const TiposContaBanco = [
  { value: '', label: '' },
  { value: 'CC_INDIVIDUAL', label: 'CC Individual' },
  { value: 'CP_INDIVIDUAL', label: 'CP Individual' },
  { value: 'CONTA_SALARIO', label: 'Conta Salário' },
  { value: 'CONTA_CONJUNTA', label: 'Conta Conjunta' },
  { value: 'CP_CONJUNTA', label: 'CP Conjunta' },
  { value: 'CONTA_INVESTIMETNO', label: 'Conta Investimento' },
];

const getLabelTiposContaBanco = (value: string) => {
  const tipoContaBanco = TiposContaBanco.find(x => x.value === value);
  return tipoContaBanco ? tipoContaBanco.label : '';
};

export { TiposContaBanco, getLabelTiposContaBanco }