const SituacaoEsteiraEnum = [
  { value: "SIM", label: "Simulação" },
  { value: "CAD", label: "Cadastrado" },
  { value: "AND", label: "Andamento" },
  { value: "PEN", label: "Pendente" },
  { value: "APR", label: "Aprovado" },
  { value: "LIB", label: "Liberado" },
  { value: "PAG", label: "Pago" },
  { value: "INT", label: "Integrado" },
  { value: "REP", label: "Reprovado" }
];

const getLabelSituacaoEsteiraEnum = (value: string) => {
  const situacao = SituacaoEsteiraEnum.find(situacao => situacao.value === value);
  return situacao ? situacao.label : 'Desconhecido';
};

export { SituacaoEsteiraEnum, getLabelSituacaoEsteiraEnum };