import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PromotoraDTO } from '../../../shared/dto/promotoraDTO';
import Spinner from '../../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonEmpregador, ButtonInativar } from '../../../shared/buttons';
import PromotoraService from '../../../services/promotoraService';
import ConfirmModal from '../../../shared/customAlertConfirm';

function PromotoraComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [promotora, setPromotora] = useState<PromotoraDTO[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);

  const promotoraService: PromotoraService = new PromotoraService();

  const listAllPromotora = async () => {
    try {
      setLoading(true);
      const responseList = await promotoraService.listarTodasPromotoras();
      const jsonListPromotora = responseList.data;

      setPromotora(jsonListPromotora);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarPromotora = async (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
       await promotoraService.ativarInativarPromotora(id);
        listAllPromotora();
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  useEffect(() => {
    listAllPromotora();
  }, []);


  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Promotora</h5>
          <Button
            onClick={() => navigate('/origens-promotora/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Nova Promotora
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table">Promotora</th>
                    <th scope="col" id="App-header-table">CNPJ</th>
                    <th scope="col" id="App-header-table">Cidade</th>
                    <th scope="col" id="App-header-table">Telefone</th>
                    <th scope="col" id="App-header-table">Email</th>
                    <th scope="col" id="App-header-table">Matriz</th>
                    <th scope="col" id="App-header-table">Alteração</th>
                    <th scope="col" id="App-header-table">Empregadores</th>
                    <th scope="col" id="App-header-table">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {promotora.map((item) => (
                    <tr>
                      <td>{item.id} - {item.nome}</td>
                      <td>{item.cnpj}</td>
                      <td>{item.cidade}</td>
                      <td>{item.telefoneComercial}</td>
                      <td>{item.email}</td>
                      <td>{item.matriz} - {item.nomeMatriz}</td>
                      <td>
                        <Link to={`/origens-promotora/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        <Link to={`/promotora-empregador`} state={{ chamada: 'promotora', idPromotora: item.id, idEmpregador: 0 }}>
                          <ButtonEmpregador />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarPromotora(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarPromotora(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}


export default PromotoraComponent;