import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../../shared/spinner';
import InputMask from 'react-input-mask';
import { Fidc } from '../../../../shared/model/fidc';
import { cnpj as cnpjValidacao } from 'cpf-cnpj-validator';
import FidcService from '../../../../services/fidcService';
import CustomAlert from '../../../../shared/customAlert';

function FidcInlusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();
  
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');

  const [loading, setLoading] = useState(false);

  const fidcService: FidcService = new FidcService();

  const listarFidcPorId = async () => {
    setLoading(true);
    try {
      const response = await fidcService.listarFidcPorId(id);
      const json = response.data;
      

      setNome(json.nome);
      setCnpj(json.cnpj);
      setBanco(json.banco);
      setAgencia(json.agencia);
      setConta(json.conta);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!cnpjValidacao.isValid(cnpj)) return setAlert({ message: 'CNPJ Inválido', type: 'warning' });
    
    let fidc: Fidc = new Fidc();
    fidc.id = id ? parseInt(id) : null;
    fidc.nome = nome;
    fidc.cnpj = cnpj;
    fidc.banco = banco;
    fidc.agencia = agencia;
    fidc.conta = conta;    

    try {
      await fidcService.incluirAlterarFidc(fidc);

      setLoading(false);
      if (tipo === 'inclusao') {
        setAlert({ message: 'FIDC salvo com sucesso!', type: 'success' });
      }else{
        setAlert({ message: 'FIDC alterado com sucesso!', type: 'success' });
      }
    } catch (err) {
      setLoading(false);
      setAlert({ message: 'Ocorreu um erro ao alterar o FIDC!', type: 'error' });
      console.error(err);
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarFidcPorId();
    }
  }, []);

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=> { setAlert(null); if (alert.type === 'success') {window.history.back();} }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>BackOffice &gt; Tabelas &gt; FIDC &gt; Inclusão</h5> : (
                <h5>BackOffice &gt; Tabelas &gt; FIDC &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            {tipo === 'alteracao' ? (
              <div className="col-md-1">
                <label>Código</label>
                <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
              </div>
            ) : null}
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>CNPJ <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Banco <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="999" type="text" className="form-control" 
                value={banco} onChange={(e) => setBanco((e.target.value).replace(/_/g, ""))}
              />
            </div>
            <div className="col-xl">
              <label>Agência <span style={{ color: 'red' }}>*</span></label> 
              <InputMask required mask="9999" type="text" className="form-control"
                value={agencia} onChange={(e) => setAgencia((e.target.value).replace(/_/g, ""))}
              />
            </div>
            <div className="col-xl">
              <label>Conta <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="999999999" type="text" className="form-control" maskChar={null}
                value={conta} onChange={(e) => setConta((e.target.value).replace(/_/g, ""))}
              />
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar FIDC': 'Salvar FIDC'}</button>
            </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default FidcInlusaoAlteracao;