import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Form } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { format } from 'date-fns';
import { faFileExcel, faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResponsivePagination from 'react-responsive-pagination';
import ArquivoService from '../../services/arquivoService';
import ComunicadoConfirmacaoService from '../../services/comunicadoConfirmacaoService';
import CustomAlert from '../../shared/customAlert';
import { ComunicadoDTO } from '../../shared/dto/comunicadoDTO';
import { TipoComunicado } from '../../shared/model/tipoComunicado';
import { FiltroComunicadoDTO } from '../../shared/dto/filtroComunicadoDTO';
import ComunicadoService from '../../services/comunicadoService';

function ComunicadosConfirmacaoComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);

  const [loading, setLoading] = useState(true);
  const [confirmacoes, setConfirmacoes] = useState<ComunicadoDTO[]>([]);
  const [tiposComunicados, setTiposComunicados] = useState<TipoComunicado[]>([]);
  const [filtros, setFiltros] = useState(false);
  const [tipoComunicadoFiltro, setTipoComunicadoFiltro] = useState<string>('');
  const [nomeComunicadoFiltro, setNomeComunicadoFiltro] = useState('');
  const [loginFiltro, setLoginFiltro] = useState('');
  const [dataInicioFiltro, setDataInicioFiltro] = useState<Date | undefined>();
  const [dataFimFiltro, setDataFimFiltro] = useState<Date | undefined>();

  const qtdRegistros = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const arquivoService: ArquivoService = new ArquivoService();
  const comunicadoConfirmacaoService: ComunicadoConfirmacaoService = new ComunicadoConfirmacaoService();
  const comunicadoService: ComunicadoService = new ComunicadoService();
  const listarTodasConfirmacoes = async (max: number) => {

    try {
      setConfirmacoes([]);
      setLoading(true);

      let filtro = new FiltroComunicadoDTO();
      filtro.tipoComunicado = tipoComunicadoFiltro;
      filtro.nomeComunicado = nomeComunicadoFiltro;
      filtro.login = loginFiltro;
      filtro.dataInicio = new Date(dataInicioFiltro!);
      filtro.dataFim = new Date(dataFimFiltro!);

      const responseList = await comunicadoConfirmacaoService.listarTodosComunicadosConfirmacao(currentPage, max, filtro);
      const jsonList: ComunicadoDTO[] = responseList.data.content;
      setConfirmacoes(jsonList);
      setTotalPages(responseList.data.totalPages);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const gerarRelatorio = async () => {
    setLoading(true);
    try {
      let filtro = new FiltroComunicadoDTO();
      filtro.tipoComunicado = tipoComunicadoFiltro;
      filtro.nomeComunicado = nomeComunicadoFiltro;
      filtro.login = loginFiltro;
      filtro.dataInicio = new Date(dataInicioFiltro!);
      filtro.dataFim = new Date(dataFimFiltro!);

      const response = await arquivoService.gerarRelatorioConfirmacaoComunicados(filtro);

      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Relatorio_Confirmacao_Comunicado.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        setAlert({ message: 'Ocorreu um erro ao gerar o relatório', type: 'error' });
      }
      console.error(err);
    }
  };

  const listarTipoComunicados = async () => {
    setLoading(true);
    try {
      const response = await comunicadoService.listarTodosTipoComunicadoAtivo();
      setTiposComunicados(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarTodasConfirmacoes(pageSize);
  }, [currentPage]);

  useEffect(() => {
    listarTipoComunicados();
  }, []);

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Comunicados &gt; Confirmação de Leitura</h5>
        </div>

        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
            <FontAwesomeIcon icon={faFilter} style={{ marginRight: '10px' }} />
            Filtros
          </Button>
          <div>

            <Button
              onClick={gerarRelatorio}
              style={{ backgroundColor: '#3d6062', borderColor: '#3d6062', marginLeft: '10px' }}
            >
              <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '10px' }} />
              Exportar Relatório
            </Button>
          </div>
        </div>
        {filtros ?
          <>
            <Row className="mb-4" style={{ marginTop: '15px' }}>
              <Form.Group as={Col} md='4' controlId="formBasicSelect">
                <Form.Label>Nome do Comunicado</Form.Label>
                <input type="text" className="form-control" value={nomeComunicadoFiltro} onChange={(e) => setNomeComunicadoFiltro(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md='4' controlId="formBasicSelect">
                <Form.Label>Login</Form.Label>
                <input type="text" className="form-control" value={loginFiltro} onChange={(e) => setLoginFiltro(e.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md='4' controlId="formBasicSelect">
                <Form.Label>Tipo de Comunicado</Form.Label>
                <select className="form-select" defaultValue={'Selecione um tipo de comunicado'} value={tipoComunicadoFiltro} onChange={(e) => setTipoComunicadoFiltro(e.target.value)}>
                  <option key={""} value={"item.id"}>
                    {"Selecione um tipo de comunicado"}
                  </option>
                  {tiposComunicados.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.id + ' - ' + item.descricao}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group as={Col} md='2'>
                <Form.Label>Data de Início</Form.Label>
                <Form.Control type="date" value={dataInicioFiltro ? dataInicioFiltro.toISOString().split('T')[0] : ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const newDate = new Date(e.target.value);
                      newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60);
                      setDataInicioFiltro(newDate);
                    } else {
                      setDataInicioFiltro(undefined);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md='1' style={{ display: 'flex', marginTop: '30px', width: '1%' }}>
                <strong style={{ marginTop: '8px' }}>a</strong>
              </Form.Group>
              <Form.Group as={Col} md='2'>
                <Form.Label>Data de Fim</Form.Label>
                <Form.Control type="date" value={dataFimFiltro ? dataFimFiltro.toISOString().split('T')[0] : ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const newDate = new Date(e.target.value);
                      newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60);
                      setDataFimFiltro(newDate);
                    } else {
                      setDataFimFiltro(undefined);
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: '1px' }} />
              </Button>
              <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => listarTodasConfirmacoes(pageSize)}>
                <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '1px' }} />
              </Button>
            </div>
          </>
          : null}

        <Row style={{ justifyContent: 'center' }} className="mb-3">
          <Form.Group style={{ marginTop: '10px' }} as={Col} md='2' controlId="formBasicSelect">Registros Por Página</Form.Group>
          <Form.Group as={Col} md='1' controlId="formBasicSelect">
            <select className="form-select"
              defaultValue={pageSize} value={pageSize} onChange={(e) => {
                {
                  setPageSize(parseInt(e.target.value));
                  listarTodasConfirmacoes(parseInt(e.target.value));
                }
              }}
            >
              {qtdRegistros.map((qtd, index) => (
                <option key={index} value={qtd}>{qtd}</option>
              ))}
            </select>
          </Form.Group>
        </Row>

        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table" style={{ width: '4%' }}>Código</th>
                    <th scope="col" id="App-header-table" style={{ width: '8%' }}>Tipo Comunicado</th>
                    <th scope="col" id="App-header-table" style={{ width: '20%' }}>Nome Comunicado</th>
                    <th scope="col" id="App-header-table" style={{ width: '9%' }}>Login</th>
                    <th scope="col" id="App-header-table" style={{ width: '5%' }}>Data e Hora Confirmação</th>
                  </tr>
                </thead>
                <tbody>
                  {confirmacoes.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.tipoComunicado}</td>
                      <td>{item.nomeComunicado}</td>
                      <td>{item.login}</td>
                      <td>{item.dtConfirmacao ? format(new Date(item.dtConfirmacao), 'dd/MM/yyyy HH:mm') : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ComunicadosConfirmacaoComponent;