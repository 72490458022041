import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../shared/buttons';
import { format } from 'date-fns';
import { Usuario } from '../../shared/model/usuario';
import { faFileExcel, faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from 'react-input-mask';
import { FiltroUsuarioDTO } from '../../shared/dto/filtroUsuarioDTO';
import ResponsivePagination from 'react-responsive-pagination';
import ArquivoService from '../../services/arquivoService';
import UsuarioService from '../../services/usuarioService';
import CustomAlert from '../../shared/customAlert';
import ConfirmModal from '../../shared/customAlertConfirm';

function UsuarioComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);

  const tpUsuario = [
    { value: '', label: '' },
    { value: 'MASTER', label: 'Master' },
    { value: 'MATRIZ', label: 'Matriz' },
    { value: 'PROMOTORA', label: 'Promotora' },
    { value: 'SUPERVISOR', label: 'Supervisor' },
    { value: 'PONTOVENDA', label: 'PontoVenda' },
  ];

  const statusFl = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Ativos', label: 'Ativos' },
    { value: 'Inativos', label: 'Inativos' },
  ];

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);

  const [filtros, setFiltros] = useState(false);
  const [codigoFiltro, setCodigoFiltro] = useState('');
  const [loginFiltro, setLoginFiltro] = useState('');
  const [nomeFiltro, setNomeFiltro] = useState('');
  const [cpfFiltro, setCpfFiltro] = useState('');
  const [statusFiltro, setStatusFiltro] = useState(statusFl[0].value);
  const [tipoUsuarioFiltro, setTipoUsuarioFiltro] = useState(tpUsuario[0].value);
  const [dataInicioFiltro, setDataInicioFiltro] = useState<Date | undefined>();
  const [dataFimFiltro, setDataFimFiltro] = useState<Date | undefined>();

  const qtdRegistros = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const arquivoService: ArquivoService = new ArquivoService();
  const usuarioService: UsuarioService = new UsuarioService();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);

  const listarTodosUsuarios = async (max: number) => {

    try {
      setLoading(true);

      let filtro = new FiltroUsuarioDTO();
      filtro.codigo = codigoFiltro;
      filtro.login = loginFiltro;
      filtro.nome = nomeFiltro;
      filtro.cpf = cpfFiltro;
      filtro.status = statusFiltro === 'Todos' ? null : statusFiltro === 'Ativos' ? true : false;
      filtro.tipoUsuario = tipoUsuarioFiltro;
      filtro.dataInicio = new Date(dataInicioFiltro!);
      filtro.dataFim = new Date(dataFimFiltro!);

      const responseList = await usuarioService.listarTodosUsuarios(currentPage, max, filtro);
      const jsonList: Usuario[] = responseList.data.content;
      setUsuarios(jsonList);
      setTotalPages(responseList.data.totalPages);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const ativarInativarUsuario = async (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
        await usuarioService.ativarInativarUsuario(id);
        listarTodosUsuarios(pageSize);
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  const gerarRelatorio = async () => {
    setLoading(true);
    try {
      let filtro = new FiltroUsuarioDTO();
      filtro.codigo = codigoFiltro;
      filtro.login = loginFiltro;
      filtro.nome = nomeFiltro;
      filtro.cpf = cpfFiltro;
      filtro.status = statusFiltro === 'Todos' ? null : statusFiltro === 'Ativos' ? true : false;
      filtro.tipoUsuario = tipoUsuarioFiltro;
      filtro.dataInicio = new Date(dataInicioFiltro!);
      filtro.dataFim = new Date(dataFimFiltro!);

      const response = await arquivoService.gerarRelatorioUsuarios(filtro);

      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Relatorio_Usuarios.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        setAlert({ message: 'Ocorreu um erro ao gerar o relatório', type: 'error' });
      }
      console.error(err);
    }
  };

  useEffect(() => {
    listarTodosUsuarios(pageSize);
  }, [currentPage]);

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Usuários</h5>
        </div>

        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
            <FontAwesomeIcon icon={faFilter} style={{ marginRight: '10px' }} />
            Filtros
          </Button>
          <div>

            <Button
              onClick={() => navigate('/sistema-usuario/inclusao', { state: { id: 0 } })}
              style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
            >
              + Novo Usuário
            </Button>
            <Button
              onClick={gerarRelatorio}
              style={{ backgroundColor: '#3d6062', borderColor: '#3d6062', marginLeft: '10px' }}
            >
              <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '10px' }} />
              Exportar Relatório
            </Button>
          </div>
        </div>
        {filtros ?
          <>
            <Row className="mb-4" style={{ marginTop: '15px' }}>
              <Form.Group as={Col} md='2' controlId="formBasicSelect">
                <Form.Label>Código Usuário </Form.Label>
                <input type="text" className="form-control" value={codigoFiltro} onChange={(e) => setCodigoFiltro(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md='4' controlId="formBasicSelect">
                <Form.Label>Login</Form.Label>
                <input type="text" className="form-control" value={loginFiltro} onChange={(e) => setLoginFiltro(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md='4' controlId="formBasicSelect">
                <Form.Label>Nome </Form.Label>
                <input type="text" className="form-control" value={nomeFiltro} onChange={(e) => setNomeFiltro(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md='2' controlId="formBasicSelect">
                <Form.Label>CPF</Form.Label>
                <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpfFiltro} onChange={(e) => setCpfFiltro(e.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md='3' controlId="formBasicSelect">
                <Form.Label>Status</Form.Label>
                <select className="form-select" defaultValue={statusFiltro} value={statusFiltro} onChange={(e) => setStatusFiltro(e.target.value)}>
                  {statusFl.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group as={Col} md='3' controlId="formBasicSelect">
                <Form.Label>Tipo Usuário</Form.Label>
                <select className="form-select" defaultValue={tpUsuario[0].value} value={tipoUsuarioFiltro} onChange={(e) => setTipoUsuarioFiltro(e.target.value)}>
                  {tpUsuario.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group as={Col} md='2'>
                <Form.Label>Data de Início</Form.Label>
                <Form.Control type="date" value={dataInicioFiltro ? dataInicioFiltro.toISOString().split('T')[0] : ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const newDate = new Date(e.target.value);
                      newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60);
                      setDataInicioFiltro(newDate);
                    } else {
                      setDataInicioFiltro(undefined);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md='1' style={{ display: 'flex', marginTop: '30px', width: '1%' }}>
                <strong style={{ marginTop: '8px' }}>a</strong>
              </Form.Group>
              <Form.Group as={Col} md='2'>
                <Form.Label>Data de Fim</Form.Label>
                <Form.Control type="date" value={dataFimFiltro ? dataFimFiltro.toISOString().split('T')[0] : ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      const newDate = new Date(e.target.value);
                      newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60);
                      setDataFimFiltro(newDate);
                    } else {
                      setDataFimFiltro(undefined);
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: '1px' }} />
              </Button>
              <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => listarTodosUsuarios(pageSize)}>
                <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '1px' }} />
              </Button>
            </div>
          </>
          : null}

        <Row style={{ justifyContent: 'center' }} className="mb-3">
          <Form.Group style={{ marginTop: '10px' }} as={Col} md='2' controlId="formBasicSelect">Registros Por Página</Form.Group>
          <Form.Group as={Col} md='1' controlId="formBasicSelect">
            <select className="form-select"
              defaultValue={pageSize} value={pageSize} onChange={(e) => {
                {
                  setPageSize(parseInt(e.target.value));
                  listarTodosUsuarios(parseInt(e.target.value));
                }
              }}
            >
              {qtdRegistros.map((qtd, index) => (
                <option key={index} value={qtd}>{qtd}</option>
              ))}
            </select>
          </Form.Group>
        </Row>

        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table" style={{ width: '4%' }}>Código</th>
                    <th scope="col" id="App-header-table" style={{ width: '8%' }}>Login</th>
                    <th scope="col" id="App-header-table" style={{ width: '20%' }}>Nome</th>
                    <th scope="col" id="App-header-table" style={{ width: '9%' }}>CPF</th>
                    <th scope="col" id="App-header-table" style={{ width: '5%' }}>Tipo</th>
                    <th scope="col" id="App-header-table" style={{ width: '15%' }}>Descrição</th>
                    <th scope="col" id="App-header-table" style={{ width: '15%' }}>E-mail</th>
                    <th scope="col" id="App-header-table" style={{ width: '10%' }}>Data Criação</th>
                    <th scope="col" id="App-header-table" style={{ width: '4%' }}>Alteração</th>
                    <th scope="col" id="App-header-table" style={{ width: '4%' }}>Inativação</th>
                  </tr>
                </thead>
                <tbody>
                  {usuarios.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.login}</td>
                      <td>{item.nome}</td>
                      <td>{item.cpf}</td>
                      <td>{item.tipoUsuario}</td>
                      <td>{item.perfil}</td>
                      <td>{item.email}</td>
                      <td>{item.dtInclusao ? format(new Date(item.dtInclusao), 'dd/MM/yyyy') : ''}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Link to={`/sistema-usuario/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarUsuario(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarUsuario(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UsuarioComponent;