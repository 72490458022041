import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import { format, parse } from 'date-fns';
import { OperacaoParcela } from '../../../shared/model/operacaoParcela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose, faCalculator, faSearch } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';
import { BaixaParcelaDTO } from '../../../shared/dto/baixaParcelaDTO';
import { MovimentoFinanceiroEnum } from '../../../shared/constantes/movimentoFinanceiroEnum';
import { HistoricoFinanceiro } from '../../../shared/model/historicoFinanceiro';
import OperacaoParcelaService from '../../../services/operacaoParcelaService';
import HistoricoFinanceiroService from '../../../services/historicoFinanceiroService';
import CustomAlert from '../../../shared/customAlert';

function BaixaParcelaManualComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [loading, setLoading] = useState(false);

  const [selectFormaPagamento, setSelectFormaPagamento] = useState('SALDO_COM_DESCONTO');
  const [dto, setDto] = useState<BaixaParcelaDTO>();
  const [parcelasList, setParcelasList] = useState<OperacaoParcela[] | null>([]);
  const [exibeDetalhe, setExibeDetalhe] = useState(false);

  const [historicoFinanceiro, setHistoricoFinanceiro] = useState('');
  const [historicoFinanceiroList, setHistoricoFinanceiroList] = useState<HistoricoFinanceiro[]>([]);

  const [contrato, setContrato] = useState('');
  const [dtMovimento, setDtMovimento] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [nomeCliente, setNomeCliente] = useState('');
  const [faixaParcela1, setFaixaParcela1] = useState('');
  const [faixaParcela2, setFaixaParcela2] = useState('');
  const [valorPago, setValorPago] = useState<number>(0);
  const [valorPagoInput, setValorPagoInput] = useState<number>(0);

  const movimento = MovimentoFinanceiroEnum;

  const operacaoParcelaService: OperacaoParcelaService = new OperacaoParcelaService();
  const historicoFinanceiroService: HistoricoFinanceiroService = new HistoricoFinanceiroService();

  const listarContrato = async () => {
    if (contrato === '') return setAlert({ message: 'Preencha o campo de busca', type: 'warning' });
    try {
      setParcelasList([]);
      setLoading(true);

      const responseList = await operacaoParcelaService.listarParcelasParaBaixa(contrato, new BaixaParcelaDTO());
      const json = responseList.data;
      setDto(json);
      setParcelasList(json.parcelas);
      setNomeCliente(json.nomeCliente);
      setFaixaParcela1(json.faixaParcela1.toString());
      setFaixaParcela2(json.faixaParcela2.toString());
      setValorPago(json.saldoTotal);
      setValorPagoInput(json.saldoTotal);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarHistoricoFinanceiro = async () => {
    try {
      setLoading(true);
      const responseList = await historicoFinanceiroService.listarHistoricoFinanceiroPorTipoEAtivo();
      const json = responseList.data;
      setHistoricoFinanceiroList(json);
      setHistoricoFinanceiro(json[0].id!.toString());
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarParcelas = async () => {
    if (contrato === '') return setAlert({ message: 'Preencha o campo de busca', type: 'warning' });
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return setAlert({ message: 'Data de movimento não pode ser superior a data de hoje', type: 'warning' });
    try {
      setParcelasList([]);
      setLoading(true);
      let dto: BaixaParcelaDTO = new BaixaParcelaDTO();
      dto.faixaParcela1 = parseInt(faixaParcela1);
      dto.faixaParcela2 = parseInt(faixaParcela2);
      dto.dtMovimento = parse(dtMovimento, "dd/MM/yyyy", new Date());

      const responseList = await operacaoParcelaService.listarParcelasParaBaixa(contrato, dto);
      const json = responseList.data;
      setDto(json);
      setParcelasList(json.parcelas);
      setNomeCliente(json.nomeCliente);
      setFaixaParcela1(json.faixaParcela1.toString());
      setFaixaParcela2(json.faixaParcela2.toString());
      setValorPago(json.saldoTotal);
      setValorPagoInput(json.saldoTotal);
      
      setExibeDetalhe(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const baixarParcelas = async () => {
    if (parse(dtMovimento, "dd/MM/yyyy", new Date()) > new Date()) return setAlert({ message: 'Data de movimento não pode ser superior a data de hoje', type: 'warning' });
    try {
      setLoading(true);
      let dto: BaixaParcelaDTO = new BaixaParcelaDTO();
      dto.faixaParcela1 = parseInt(faixaParcela1);
      dto.faixaParcela2 = parseInt(faixaParcela2);
      dto.dtMovimento = parse(dtMovimento, "dd/MM/yyyy", new Date());
      dto.valorMovimento = parseFloat(valorPagoInput.toString().replace(',', '.'));
      dto.movimento = selectFormaPagamento;
      dto.parcelas = parcelasList!;
      dto.idHistoricoFinanceiro = historicoFinanceiro === '' ? null : parseInt(historicoFinanceiro);
  
      await operacaoParcelaService.baixarParcelas(contrato, dto);
      setLoading(false);
      setAlert({ message: 'Parcelas baixadas com sucesso', type: 'success' });
      
      limpaDados();
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectFormaPagamento(selectedValue);

    if (selectedValue === 'SALDO_COM_DESCONTO' || selectedValue === 'DISTRIBUIR_CONF_SALDO') {
      setValorPagoInput(dto?.saldoTotal! || 0);
    } else if (selectedValue === 'VALOR_FINAL_PARCELA' || selectedValue === 'DISTRIBUIR_CONF_VALOR_FINAL') {
      setValorPagoInput(dto?.valorFinal || 0);
    }
  };

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    if (value) {
      value = (parseFloat(value) / 100).toFixed(2);
      value = value.replace('.', ',');
    }
    setValorPagoInput(value);
  };

  const limpaDados = () => {
    setExibeDetalhe(false);  
    setNomeCliente(''); 
    setFaixaParcela1(''); 
    setFaixaParcela2(''); 
    setValorPago(0);
    setParcelasList([]);
  }

  useEffect(() => {
    listarHistoricoFinanceiro();
    const today = new Date();
    const formattedDate = today.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }, []);

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div className="card" style={{ marginTop: '25px' }}>
              <div className="card-body">
                <div className="card-header">
                  <strong>Baixa de Parcela</strong>
                </div>
                <div className="row">
                  <label style={{ marginTop: '10px' }}>Histórico Financeiro</label>
                  <div className="col-md-8" style={{ display: 'flex', backgroundColor: 'white' }}>
                    <select className="form-select" style={{ backgroundColor: 'LightGrey', marginRight: '10px', width: '25%' }}
                      value={historicoFinanceiro} onChange={(e) => setHistoricoFinanceiro(e.target.value)}>
                      {historicoFinanceiroList.map((item) => (
                        <option key={item.id} value={item.id!}>{item.id + ' - ' + item.nome}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Contrato <span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
                        value={contrato} onChange={(e) => { setContrato((e.target.value)); limpaDados() }} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="input-group-btn"
                      style={{
                        backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
                      }} onClick={listarContrato}>
                      <a >
                        <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
                      </a>
                    </button>
                  </div>
                </div>

                {exibeDetalhe ? (
                  <>
                    <div className="row" style={{ marginTop: '10px' }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Nome Cliente</span>
                          <input type="text" value={nomeCliente} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                      <div className="col-md-8">
                        <div className="col-md-4">
                          <label>Faixa de Parcela <span style={{ color: 'red' }}>*</span></label>
                          <thead>
                            <tr>
                              <th style={{ width: "150px" }}><InputMask required maskChar="" mask="99" type="text" className="form-control" value={faixaParcela1} onChange={(e) => { setFaixaParcela1((e.target.value).replace(/_/g, "")); setParcelasList(null); }} /></th>
                              <th style={{ width: "15px" }}> a </th>
                              <th style={{ width: "150px" }}><InputMask required maskChar="" mask="99" type="text" className="form-control" value={faixaParcela2} onChange={(e) => { setFaixaParcela2((e.target.value).replace(/_/g, "")); setParcelasList(null); }} /></th>
                            </tr>
                          </thead>
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ marginTop: '10px' }}>
                      <div className="row">
                        <div className="col-md-3" style={{ paddingRight: '10px' }}>
                          <label style={{ width: '100%' }}>Movimento Financeiro</label>
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          <label style={{ width: '100%' }}>Data Movimento</label>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-md-3" style={{ paddingRight: '10px' }}>
                          <select className="form-select" value={selectFormaPagamento} onChange={handleSelectChange}>
                            {Object.keys(movimento).map((key) => (
                              <option value={key}>{MovimentoFinanceiroEnum[key as keyof typeof MovimentoFinanceiroEnum]}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: '10px' }}>
                          <InputMask required mask="99/99/9999" className="form-control" type="text"
                            value={dtMovimento} onChange={(e) => setDtMovimento((e.target.value).replace(/_/g, ""))} />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '15px' }}>
                      <div className="col-md-3">
                        <button className="input-group-btn"
                          style={{
                            width: '40%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                            justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '50px', minWidth: '50px'
                          }} onClick={listarParcelas}>
                          <a>
                            <FontAwesomeIcon icon={faCalculator} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                          </a>
                          <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Calcular</strong>
                        </button>
                      </div>
                    </div>
                    {parcelasList != null && parcelasList!.length > 0 ? (
                      <div className="table-responsive">
                        <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                          <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                            <tbody>
                              <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black' }}>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">NÚMERO</th>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">VENCIMENTO</th>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">STATUS</th>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">ATRASO</th>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">VALOR PARCELA</th>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">DESCONTO</th>
                                <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">SALDO</th>
                              </tr>
                              {parcelasList.map((item) => (
                                <tr className="GridRowStyle">
                                  <td style={{ textAlign: 'center' }}>{item.numeroParcela}</td>
                                  <td style={{ textAlign: 'center' }}>{item.dtVencimento ? format(new Date(item.dtVencimento), 'dd/MM/yyyy') : ''}</td>
                                  <td style={{ textAlign: 'center' }}>{item.tipoMovimentacao !== null ? (item.tipoMovimentacao !== 'BAIXA_PARCIAL' ? 'BAIXA_TOTAL' : item.tipoMovimentacao)?.replace(/_/g, ' ') : ''}</td>
                                  <td style={{ textAlign: 'center' }}>{item.atraso ? `${item.atraso}` : '0'}</td>
                                  <td style={{ textAlign: 'center' }}>R$ {item.valorParcela!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  <td style={{ textAlign: 'center' }}>R$ {item.desconto !== null ? item.desconto!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                                  <td style={{ textAlign: 'center' }}>R$ {item.tipoMovimentacao !== null && item.tipoMovimentacao?.startsWith('BAIXA') && item.tipoMovimentacao !== 'BAIXA_PARCIAL' ? '0,00' : item.saldo!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span style={{ textAlign: 'left' }}>Valor Final</span>
                              <input type="text" value={'R$ ' + (dto?.valorFinal ? dto?.valorFinal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00')} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span style={{ textAlign: 'left' }}>Saldo Total</span>
                              <input type="text" value={'R$ ' + (dto?.saldoTotal ? dto?.saldoTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00')} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Valor Pago</label>
                              <input type="text" className="form-control form-control-sm brds "
                                value={valorPagoInput.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={handleInputChange}
                                readOnly={selectFormaPagamento === 'SALDO_COM_DESCONTO' || selectFormaPagamento === 'VALOR_FINAL_PARCELA'}
                                style={selectFormaPagamento === 'SALDO_COM_DESCONTO' || selectFormaPagamento === 'VALOR_FINAL_PARCELA' ? { color: 'Black', backgroundColor: 'LightGrey' } : { color: 'Black', backgroundColor: 'White' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: '10px', height: '50px', maxWidth: '70%' }}>
                          <div className="col-md-3">
                            <button className="input-group-btn"
                              style={{
                                width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                                justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                              }} onClick={baixarParcelas}>
                              <a>
                                <FontAwesomeIcon icon={faSave} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                              </a>
                              <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Confirmar Baixa</strong>
                            </button>
                          </div>
                          <div className="col-md-3">
                            <button className="input-group-btn"
                              style={{
                                width: '80%', display: 'flex', alignItems: 'center', backgroundColor: 'red', borderColor: 'red',
                                justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                              }} onClick={() => window.history.back()}>
                              <a>
                                <FontAwesomeIcon icon={faClose} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                              </a>
                              <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Cancelar</strong>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : parcelasList != null ? (
                      <div style={{ width: '100%', borderWidth: '1px', marginTop: '10px' }}>
                        <span style={{ color: 'red' }}>Não há saldo em aberto para este Contrato!</span>
                      </div>
                    ) : null}

                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div >
    </>
  );
}

export default BaixaParcelaManualComponent;
