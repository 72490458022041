import createAxiosInstance from './axiosConfig';
import { Comunicado } from '../shared/model/comunicado';
import { TipoComunicado } from '../shared/model/tipoComunicado';
import { UUID } from 'crypto';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/comunicadoController');

export default class ComunicadoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosComunicados() {
    return await this.axios.get(`/listarTodosComunicados`);
  };

  async listarTodosTipoComunicadoAtivo() {
    return await this.axios.get(`/listarTodosTipoComunicadoAtivo`);
  };

  async listarComunicadoPorId(id: string) {
    return await this.axios.get(`/listarComunicadoPorId?id=${id}`);
  };

  async inclusaoAlteracaoComunicado(dto: Comunicado) {
    return await this.axios.post(`/inclusaoAlteracaoComunicado`, dto);
  };

  async inclusaoTipoComunicado(dto: TipoComunicado) {
    return await this.axios.post(`/inclusaoTipoComunicado`, dto);
  };

  async inativarTipoComunicadoAtivo(id: number) {
    return await this.axios.get(`/inativarTipoComunicadoAtivo?idTipoArquivo=${id}`);
  };

  async listarTodosComunicadosVigentes() {
    return await this.axios.get(`/listarTodosComunicadosVigentes`);
  };

  async downloadComunicadoPorCaminhoArquivo(caminhoArquivo: string) {
    return await this.axios.get(`/downloadComunicadoPorCaminhoArquivo?caminhoArquivo=${caminhoArquivo}`,
      { responseType: 'blob' });
  };

  async exibirComunicadoUsuario() {
    return await this.axios.get(`/exibirComunicadoUsuario`);
  };

  async confirmacaoLeituraComunicado(codigoComunicado: UUID) {
    return await this.axios.get(`/confirmacaoLeituraComunicado?codigoComunicado=${codigoComunicado}`);
  };

}