import createAxiosInstance from './axiosConfig';
import { PropostaDTO } from '../shared/dto/propostaDTO';
import { FiltroPropostaDTO } from '../shared/dto/filtroPropostaDTO';
import { Cliente } from '../shared/model/Cliente';
import { CalculoResultadoSimulacaoDTO } from '../shared/dto/calculoResultadoSimulacaoDTO';
import { ResultadoSimulacaoPropostaDTO } from '../shared/dto/resultadoSimulacaoPropostaDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/propostaController');

export default class PropostaService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodasPropostas(currentPage: number, max: number, dto: FiltroPropostaDTO) {
    return await this.axios.post(`/listarTodasPropostas?page=${currentPage}&max=${max}`, dto);
  };

  async listarPropostaEspelhoJasper(idProposta: number) {
    return await this.axios.get(`/listarPropostaEspelhoJasper?idProposta=${idProposta}`, {
      responseType: 'blob',
    });
  };

  async aprovarReprovarEmLote(isAprovar: boolean, motivo: string | null, dto: PropostaDTO[]) {
    return await this.axios.post(`/aprovarReprovarEmLote?isAprovar=${isAprovar}&idMotivo=${motivo}`, dto);
  };

  async listarPropostaEFluxoAnteriores(idProposta: string) {
    return await this.axios.get(`/listarPropostaEFluxoAnteriores?idProposta=${idProposta}`);
  };

  async obterMargemOnline(codigoExterno: string, empregador: string, dto: Cliente) {
    return await this.axios.post(`/obterMargemOnline?convenio=${codigoExterno!}&empregador=${empregador!}`, dto);
  };

  async simulacaoRMCTotal(dto: CalculoResultadoSimulacaoDTO) {
    return await this.axios.post(`/simulacaoRMCTotal`, dto);
  };

  async resultadoSimulacaoParcela(dto: ResultadoSimulacaoPropostaDTO) {
    return await this.axios.post(`/resultadoSimulacaoParcela`, dto);
  };

  async inclusaoAlteracaoProposta(dto: PropostaDTO) {
    return await this.axios.post(`/inclusaoAlteracaoProposta`, dto);
  };

}