import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import InputMask from 'react-input-mask';
import { format, parse } from 'date-fns';
import { Empregador } from '../../../shared/model/empregador';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Fidc } from '../../../shared/model/fidc';
import { Cessao } from '../../../shared/model/cessao';
import { RelatoriosDTO } from '../../../shared/dto/relatoriosDTO';
import ArquivoService from '../../../services/arquivoService';
import CessaoService from '../../../services/cessaoService';
import EmpregadorService from '../../../services/empregadorService';
import FidcService from '../../../services/fidcService';
import CustomAlert from '../../../shared/customAlert';

function ContratosCedidosComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [loading, setLoading] = useState(false);

  const [dtBase1, setDtBase1] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtBase2, setDtBase2] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [dtCessao1, setDtCessao1] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [dtCessao2, setDtCessao2] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [availableFidcs, setAvailableFidcs] = useState<Fidc[]>([]);
  const [selectedFidcs, setSelectedFidcs] = useState<Fidc[]>([]);
  const [selectedToMoveFidcs, setSelectedToMoveFidcs] = useState<Fidc[]>([]);
  const [selectedFromSelectedFidcs, setSelectedFromSelectedFidcs] = useState<Fidc[]>([]);

  const [availableEmpregadores, setAvailableEmpregadores] = useState<Empregador[]>([]);
  const [selectedEmpregadores, setSelectedEmpregadores] = useState<Empregador[]>([]);
  const [selectedToMoveEmpregadores, setSelectedToMoveEmpregadores] = useState<Empregador[]>([]);
  const [selectedFromSelectedEmpregadores, setSelectedFromSelectedEmpregadores] = useState<Empregador[]>([]);

  const [availableCessoes, setAvailableCessoes] = useState<Cessao[]>([]);
  const [selectedCessoes, setSelectedCessoes] = useState<Cessao[]>([]);
  const [selectedToMoveCessoes, setSelectedToMoveCessoes] = useState<Cessao[]>([]);
  const [selectedFromSelectedCessoes, setSelectedFromSelectedCessoes] = useState<Cessao[]>([]);

  const arquivoService: ArquivoService = new ArquivoService();
  const cessaoService: CessaoService = new CessaoService();
  const empregadorService: EmpregadorService = new EmpregadorService();
  const fidcService: FidcService = new FidcService();

  const listarFidcs = async () => {
    setLoading(true);
    try {
      const response = await fidcService.listarTodosFidcs();
      
      setAvailableFidcs(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarCessoes = async () => {
    setLoading(true);
    try {
      const response = await cessaoService.listarTodasCessoes();
      setAvailableCessoes(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarEmpregadores = async () => {
    setLoading(true);
    try {
      const response = await empregadorService.listarTodosEmpregadoresAtivos();
      setAvailableEmpregadores(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const gerarRelatorio = async () => {
    if (selectedEmpregadores.length <= 0) return setAlert({ message: 'Selecione ao menos um Empregador', type: 'warning' });
    if (selectedFidcs.length <= 0) return setAlert({ message: 'Selecione ao menos um FIDC', type: 'warning' });
    if (selectedCessoes.length <= 0) return setAlert({ message: 'Selecione ao menos uma Cessão', type: 'warning' });
    if (dtBase1 === '' || dtBase2 === '') return setAlert({ message: 'Preencha os campos de datas base!', type: 'warning' });
    if (dtBase1 !== '' && dtBase2 !== '' && parse(dtBase1, "dd/MM/yyyy", new Date()) > parse(dtBase2, "dd/MM/yyyy", new Date())) return setAlert({ message: 'Data base inicial deve ser inferior a data base final', type: 'warning' });
    if (dtCessao1 === '' || dtCessao2 === '') return setAlert({ message: 'Preencha os campos de datas cessão!', type: 'warning' });
    if (dtCessao1 !== '' && dtCessao2 !== '' && parse(dtCessao1, "dd/MM/yyyy", new Date()) > parse(dtCessao2, "dd/MM/yyyy", new Date())) return setAlert({ message: 'Data cessão inicial deve ser inferior a data cessão final', type: 'warning' });

    setLoading(true);
    try {
      const dto: RelatoriosDTO = new RelatoriosDTO();
      dto.empregadores = selectedEmpregadores;
      dto.fidcs = selectedFidcs;
      dto.cessoes = selectedCessoes;
      dto.dtBase1 = parse(dtBase1, "dd/MM/yyyy", new Date());
      dto.dtBase2 = parse(dtBase2, "dd/MM/yyyy", new Date());
      dto.dtCessao1 = parse(dtCessao1, "dd/MM/yyyy", new Date());
      dto.dtCessao2 = parse(dtCessao2, "dd/MM/yyyy", new Date());

      const response = await arquivoService.gerarRelatorioContratosCedidos(dto);

      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Relatorio_Contratos_Cedidos.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        setAlert({ message: 'Ocorreu um erro ao gerar o relatório', type: 'error' });
      }
      console.error(err);
    }
  };



  useEffect(() => {
    listarFidcs();
    listarCessoes();
    listarEmpregadores();
  }, []);

  const moveToSelectedFidcs = () => {
    const newSelected = [...selectedFidcs, ...selectedToMoveFidcs];
    const newAvailable = availableFidcs.filter(
      (fidc) => !selectedToMoveFidcs.includes(fidc)
    );

    setAvailableFidcs(newAvailable);
    setSelectedFidcs(newSelected);
    setSelectedToMoveFidcs([]);
  };

  const moveToAvailableFidcs = () => {
    const newAvailable = [...availableFidcs, ...selectedFromSelectedFidcs];
    const newSelected = selectedFidcs.filter(
      (fidc) => !selectedFromSelectedFidcs.includes(fidc)
    );

    setAvailableFidcs(newAvailable);
    setSelectedFidcs(newSelected);
    setSelectedFromSelectedFidcs([]);
  };

  const moveAllToSelectedFidcs = () => {
    const newSelected = [...selectedFidcs, ...availableFidcs];
    setSelectedFidcs(newSelected);
    setAvailableFidcs([]);
  };

  const moveAllToAvailableFidcs = () => {
    const newAvailable = [...availableFidcs, ...selectedFidcs];
    setAvailableFidcs(newAvailable);
    setSelectedFidcs([]);
  };

  //EMPREGADOR

  const moveToSelectedEmpregadores = () => {
    const newSelected = [...selectedEmpregadores, ...selectedToMoveEmpregadores];
    const newAvailable = availableEmpregadores.filter(
      (empregador) => !selectedToMoveEmpregadores.includes(empregador)
    );

    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores(newSelected);
    setSelectedToMoveEmpregadores([]);
  };

  const moveToAvailableEmpregadores = () => {
    const newAvailable = [...availableEmpregadores, ...selectedFromSelectedEmpregadores];
    const newSelected = selectedEmpregadores.filter(
      (empregador) => !selectedFromSelectedEmpregadores.includes(empregador)
    );

    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores(newSelected);
    setSelectedFromSelectedEmpregadores([]);
  };

  const moveAllToSelectedEmpregadores = () => {
    const newSelected = [...selectedEmpregadores, ...availableEmpregadores];
    setSelectedEmpregadores(newSelected);
    setAvailableEmpregadores([]);
  };

  const moveAllToAvailableEmpregadores = () => {
    const newAvailable = [...availableEmpregadores, ...selectedEmpregadores];
    setAvailableEmpregadores(newAvailable);
    setSelectedEmpregadores([]);
  };

  //EMPREGADOR

  const moveToSelectedCessoes = () => {
    const newSelected = [...selectedCessoes, ...selectedToMoveCessoes];
    const newAvailable = availableCessoes.filter(
      (cessao) => !selectedToMoveCessoes.includes(cessao)
    );

    setAvailableCessoes(newAvailable);
    setSelectedCessoes(newSelected);
    setSelectedToMoveCessoes([]);
  };

  const moveToAvailableCessoes = () => {
    const newAvailable = [...availableCessoes, ...selectedFromSelectedCessoes];
    const newSelected = selectedCessoes.filter(
      (cessao) => !selectedFromSelectedCessoes.includes(cessao)
    );

    setAvailableCessoes(newAvailable);
    setSelectedCessoes(newSelected);
    setSelectedFromSelectedCessoes([]);
  };

  const moveAllToSelectedCessoes = () => {
    const newSelected = [...selectedCessoes, ...availableCessoes];
    setSelectedCessoes(newSelected);
    setAvailableCessoes([]);
  };

  const moveAllToAvailableCessoes = () => {
    const newAvailable = [...availableCessoes, ...selectedCessoes];
    setAvailableCessoes(newAvailable);
    setSelectedCessoes([]);
  };

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      <div className="position-relative m-md-3">
        {loading ? <Spinner loading={loading} /> : (
          <div className="card" style={{ marginTop: '25px' }}>
            <div className="card-header">
              <strong>Relatório de Contratos Cedidos</strong>
            </div>
            <div className="card-body">
              <div className="" style={{ marginTop: '10px' }}>
                <div className="row">
                  <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <label>Período de Data Base <span style={{ color: 'red' }}>*</span></label>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                  <div className="col-md-4">
                    <thead>
                      <tr>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase1} onChange={(e) => setDtBase1((e.target.value).replace(/_/g, ""))} /></th>
                        <th style={{ width: "15px" }}> a </th>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtBase2} onChange={(e) => setDtBase2((e.target.value).replace(/_/g, ""))} /></th>
                      </tr>
                    </thead>
                  </div>
                </div>
              </div>
              <div className="" style={{ marginTop: '10px' }}>
                <div className="row">
                  <div className="col-md-2" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <label>Período de Data da Cessão <span style={{ color: 'red' }}>*</span></label>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '5px' }}>
                  <div className="col-md-4">
                    <thead>
                      <tr>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtCessao1} onChange={(e) => setDtCessao1((e.target.value).replace(/_/g, ""))} /></th>
                        <th style={{ width: "15px" }}> a </th>
                        <th style={{ width: "150px" }}><InputMask required mask="99/99/9999" className="form-control" type="text" value={dtCessao2} onChange={(e) => setDtCessao2((e.target.value).replace(/_/g, ""))} /></th>
                      </tr>
                    </thead>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <h6>Fidcs Disponíveis</h6>
                  <select
                    multiple
                    value={selectedToMoveFidcs.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const fidc = availableFidcs.find(p => p.id!.toString() === options[i].value);
                          if (fidc) {
                            selected.push(fidc);
                          }
                        }
                      }
                      setSelectedToMoveFidcs(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {availableFidcs.map((fidc) => (
                      <option key={fidc.id} value={fidc.id!.toString()}>
                        {fidc.id} - {fidc.nome!}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <button onClick={moveToSelectedFidcs} style={{ margin: '5px' }}>{'>'}</button>
                  <button onClick={moveAllToSelectedFidcs} style={{ margin: '5px' }}>{'>>'}</button>
                  <button onClick={moveToAvailableFidcs} style={{ margin: '5px' }}>{'<'}</button>
                  <button onClick={moveAllToAvailableFidcs} style={{ margin: '5px' }}>{'<<'}</button>
                </div>
                <div>
                  <h6>Fidcs Selecionados</h6>
                  <select
                    multiple
                    value={selectedFromSelectedFidcs.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const fidc = selectedFidcs.find(p => p.id!.toString() === options[i].value);
                          if (fidc) {
                            selected.push(fidc);
                          }
                        }
                      }
                      setSelectedFromSelectedFidcs(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {selectedFidcs.map((fidc) => (
                      <option key={fidc.id} value={fidc.id!.toString()}>
                        {fidc.id} - {fidc.nome!}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <h6>Cessões Disponíveis</h6>
                  <select
                    multiple
                    value={selectedToMoveCessoes.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const cessao = availableCessoes.find(p => p.id!.toString() === options[i].value);
                          if (cessao) {
                            selected.push(cessao);
                          }
                        }
                      }
                      setSelectedToMoveCessoes(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {availableCessoes.map((cessao) => (
                      <option key={cessao.id} value={cessao.id!.toString()}>
                        {cessao.id} - {cessao.nome!}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <button onClick={moveToSelectedCessoes} style={{ margin: '5px' }}>{'>'}</button>
                  <button onClick={moveAllToSelectedCessoes} style={{ margin: '5px' }}>{'>>'}</button>
                  <button onClick={moveToAvailableCessoes} style={{ margin: '5px' }}>{'<'}</button>
                  <button onClick={moveAllToAvailableCessoes} style={{ margin: '5px' }}>{'<<'}</button>
                </div>
                <div>
                  <h6>Cessões Selecionadas</h6>
                  <select
                    multiple
                    value={selectedFromSelectedCessoes.map((p) => p.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const cessao = selectedCessoes.find(p => p.id!.toString() === options[i].value);
                          if (cessao) {
                            selected.push(cessao);
                          }
                        }
                      }
                      setSelectedFromSelectedCessoes(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {selectedCessoes.map((cessao) => (
                      <option key={cessao.id} value={cessao.id!.toString()}>
                        {cessao.id} - {cessao.nome!}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <h6>Empregadores Disponíveis </h6>
                  < select
                    multiple
                    value={selectedToMoveEmpregadores.map((e) => e.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const empregador = availableEmpregadores.find(e => e.id!.toString() === options[i].value);
                          if (empregador) {
                            selected.push(empregador);
                          }
                        }
                      }
                      setSelectedToMoveEmpregadores(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {
                      availableEmpregadores.map((empregador) => (
                        <option key={empregador.id} value={empregador.id!.toString()} >
                          {empregador.id} - {empregador.nome!}
                        </option>
                      ))
                    }
                  </select>
                </div>
                < div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <button onClick={moveToSelectedEmpregadores} style={{ margin: '5px' }}> {'>'} </button>
                  < button onClick={moveAllToSelectedEmpregadores} style={{ margin: '5px' }}> {'>>'} </button>
                  < button onClick={moveToAvailableEmpregadores} style={{ margin: '5px' }}> {'<'} </button>
                  < button onClick={moveAllToAvailableEmpregadores} style={{ margin: '5px' }}> {'<<'} </button>
                </div>
                < div >
                  <h6>Empregadores Selecionados </h6>
                  < select
                    multiple
                    value={selectedFromSelectedEmpregadores.map((e) => e.id!.toString())}
                    onChange={(e) => {
                      const options = e.target.options;
                      const selected = [];
                      for (let i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                          const empregador = selectedEmpregadores.find(e => e.id!.toString() === options[i].value);
                          if (empregador) {
                            selected.push(empregador);
                          }
                        }
                      }
                      setSelectedFromSelectedEmpregadores(selected);
                    }}
                    style={{ width: '575px', height: '250px' }}
                  >
                    {
                      selectedEmpregadores.map((empregador) => (
                        <option key={empregador.id} value={empregador.id!.toString()} >
                          {empregador.id} - {empregador.nome!}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>

              <div className="row" style={{ marginTop: '20px', height: '50px', maxWidth: '70%' }}>
                <div className="col-md-3">
                  <button className="input-group-btn"
                    style={{
                      width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                      justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                    }} onClick={gerarRelatorio}>
                    <a>
                      <FontAwesomeIcon icon={faPrint} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                    </a>
                    <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Gerar Relatório</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
}
export default ContratosCedidosComponent;