import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../../shared/spinner';
import { ButtonAlterar } from '../../../../shared/buttons';
import { Fidc } from '../../../../shared/model/fidc';
import FidcService from '../../../../services/fidcService';

function FidcComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fidcs, setFidcs] = useState<Fidc[]>([]);

  const fidcService: FidcService = new FidcService();

  const listarTodosFidcs = async () => {
    try {
      setLoading(true);
      const responseList = await fidcService.listarTodosFidcs();
      setFidcs(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarTodosFidcs();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>BackOffice &gt; Tabelas &gt; Fundo de Investimento em Direitos Creditórios</h5>
          <Button
            onClick={() => navigate('/backoffice-tabelas/fidc/inclusao', { state: { id: 0 } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Novo FIDC
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table">Código</th>
                    <th scope="col" id="App-header-table">Nome</th>
                    <th scope="col" id="App-header-table">CNPJ</th>
                    <th scope="col" id="App-header-table">Banco</th>
                    <th scope="col" id="App-header-table">Agência</th>
                    <th scope="col" id="App-header-table">Conta</th>
                    <th scope="col" id="App-header-table">Alteração</th>
                  </tr>
                </thead>
                <tbody>
                  {fidcs.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.nome}</td>
                      <td>{item.cnpj}</td>
                      <td>{item.banco}</td>
                      <td>{item.agencia}</td>
                      <td>{item.conta}</td>
                      <td>
                        <Link to={`/backoffice-tabelas/fidc/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div >
    </>
  );
}

export default FidcComponent;