import { PromotoraEmpregadorDTO } from '../shared/dto/promotoraEmpregadorDTO';
import createAxiosInstance from './axiosConfig';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/promotoraEmpregadorController');

export default class PromotoraEmpregadorService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarEmpregadorPorPromotora(id: string) {
    return await this.axios.get(`/listarEmpregadorPorPromotora?idPromotora=${id}`);
  };

  async listarPromotorasEmpregadores(idPromotora: string, idEmpregador: string) {
    return await this.axios.get(`/listarPromotorasEmpregadores?idPromotora=${idPromotora}&idEmpregador=${idEmpregador}`);
  };
  
  async incluirAlterarPromotoraEmpregador(chamada: string, id: string, dto: PromotoraEmpregadorDTO[]) {
    return await this.axios.post(`/incluirAlterarPromotoraEmpregador?chamadaPromotora=${chamada === 'promotora' ? true : false}&promotoraEmpregadorid=${id}`, dto);
  };

}