import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Spinner from '../../../shared/spinner';
import { Orgao } from '../../../shared/model/orgao';
import { ButtonAlterarOrgao, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import EmpregadorService from '../../../services/empregadorService';
import ConfirmModal from '../../../shared/customAlertConfirm';
import ArquivoService from '../../../services/arquivoService';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArquivoDTO } from '../../../shared/dto/arquivoDTO';
import CustomAlert from '../../../shared/customAlert';

function OrgaoComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { idEmpregador } = location.state || {};
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);

  const [loading, setLoading] = useState(true);
  const [orgao, setOrgao] = useState<Orgao[]>([]);
  const [id, setId] = useState<number>();
  const [ativo, setAtivo] = useState<boolean>(false);
  const [nome, setNome] = useState('');
  const [codigoExterno, setCodigoExterno] = useState<number>();
  const [codigoAverbacao, setCodigoAverbacao] = useState<number>();
  const [alteracao, setAlteracao] = useState<boolean>(false);
  const [arquivo, setArquivo] = useState<File | null>(null);
  const [arquivoBase64, setArquivoBase64] = useState<string>('');
  const [refresh, setRefresh] = useState(false);

  const empregadorService: EmpregadorService = new EmpregadorService();
  const arquivoService: ArquivoService = new ArquivoService();

  const listarPorEmpregador = async () => {
    try {
      setLoading(true);
      limparCampos();
      setAlteracao(false);
      const responseList = await empregadorService.listarOrgaoPorEmpregador(idEmpregador);
      const jsonListOrgao = responseList.data;
      setOrgao(jsonListOrgao);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarOrgao = async (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
        await empregadorService.ativarInativarOrgao(id);
        listarPorEmpregador();
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  const alterarOrgao = (id: number, nome: string, ativo: boolean, codigoExterno: number, codigoAverbacao: number) => {

    setAlteracao(true);
    setId(id);
    setNome(nome);
    setAtivo(ativo);
    setCodigoExterno(codigoExterno);
    setCodigoAverbacao(codigoAverbacao);
  }

  const limparCampos = () => {
    setId(NaN);
    setNome('');
    setCodigoAverbacao(NaN);
    setCodigoExterno(NaN);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      let org: Orgao = new Orgao();
      org.idEmpregador = parseInt(idEmpregador!);
      org.id = id!;
      org.nome = nome;
      org.ativo = ativo;
      org.codigoExterno = codigoExterno!;
      org.codigoAverbacao = codigoAverbacao!;
      await empregadorService.incluirAlterarOrgao(org)
        .then(() => setLoading(false));

      listarPorEmpregador();
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleDownloadModelo = async () => {
    try {
      const response = await arquivoService.downloadArquivoCadastroOrgaos();

      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'Layout - Cadastro_Orgaos.xlsx');

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  const uploadArquivo = async () => {
    if (arquivo) {
      setLoading(true);
      try {
        let arquivoDTO = new ArquivoDTO();
        arquivoDTO.arquivoBase64 = arquivoBase64.split(',')[1];
        await empregadorService.processarArquivoLote(idEmpregador, arquivoDTO)
          .then((resp) => {
            setLoading(false);
            setAlert({ message: resp.data, type: 'success' });
            setRefresh(!refresh);
          });
      } catch (err: any) {
        console.error(err);
        setAlert({ message: err.response.data, type: 'success' });
        setLoading(false);
      }
    }
  };

  const handleClickUpload = (event: any) => {
    setArquivo(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        setArquivoBase64(e.target.result);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(() => {
    listarPorEmpregador();
  }, [refresh]);

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Parâmetros Cartão &gt; Empregador  &gt; Órgão</h5>
          <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
            Voltar
          </Button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-md-2">
              <label>Código Externo</label>
              <InputMask mask="999999999" type="text" className="form-control" value={codigoExterno} onChange={(e) => setCodigoExterno(parseInt((e.target.value).replace(/_/g, "")))} />
            </div>
            <div className="col-md-2">
              <label>Código Averbação</label>
              <InputMask mask="999999999" type="text" className="form-control" value={codigoAverbacao} onChange={(e) => setCodigoAverbacao(parseInt((e.target.value).replace(/_/g, "")))} />
            </div>
            <div className="col-md-4">
              {alteracao ? (
                <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                  <a >
                    <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Alterar Órgão</button>
                  </a>
                  <a >
                    <button className="w-40 btn btn-lg" id="App-button-verde" onClick={() => { setAlteracao(false); limparCampos() }}>Cancelar Alteração</button>
                  </a>
                </div>
              ) : (
                <div className="col-xl" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                  <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Adicionar Órgão</button>
                </div>
              )}
            </div>
          </div>
        </form>

        <div className="row" style={{ marginTop: '10px' }}>
          <div className="col-md-3">
            <button className="input-group-btn" style={{
              width: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#000041', borderColor: '#000041',
              justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
            }}
              onClick={handleDownloadModelo}
            >
              <FontAwesomeIcon icon={faDownload} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
              <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Download Arquivo Modelo</strong>
            </button>
          </div>
        </div>

        <div className="row" style={{ marginTop: '10px' }}>
          <div className="col-md-4">
            <div className="form-group">
              <span>Arquivo</span>
              <br />
              <input
                style={{ backgroundColor: '#24393A', borderColor: '#24393A', border: '1px solid black', color: 'White', width: '90%' }}
                type="file"
                className="form-control-file btn btn-info"
                onChange={(e) => handleClickUpload(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" style={{ marginTop: '25px' }}>
              <a className="btn btn-success" onClick={uploadArquivo}>
                <FontAwesomeIcon icon={faUpload} style={{ color: 'White', fontSize: 'larger', marginTop: '5px', marginRight: '5px' }} />
                Importar Arquivo
              </a>
            </div>
          </div>
        </div>

        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive" style={{ marginTop: '10px' }}>
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table">Código</th>
                    <th scope="col" id="App-header-table">Nome</th>
                    <th scope="col" id="App-header-table">Código Externo</th>
                    <th scope="col" id="App-header-table">Código Averbação</th>
                    <th scope="col" id="App-header-table">Alteração</th>
                    <th scope="col" id="App-header-table">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orgao.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.nome}</td>
                      <td>{item.codigoExterno}</td>
                      <td>{item.codigoAverbacao}</td>
                      <td>
                        <ButtonAlterarOrgao onClick={() => alterarOrgao(item.id!, item.nome!, item.ativo!, item.codigoExterno!, item.codigoAverbacao!)} />
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarOrgao(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarOrgao(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OrgaoComponent;