import createAxiosInstance from './axiosConfig';
import { PromotoraEmpregadorDTO } from '../shared/dto/promotoraEmpregadorDTO';
import { Empregador } from '../shared/model/empregador';
import { Orgao } from '../shared/model/orgao';
import { ArquivoDTO } from '../shared/dto/arquivoDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/empregadorController');

export default class EmpregadorService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosEmpregadoresAtivos() {
    return await this.axios.get(`/listarTodosEmpregadoresAtivos`);
  };

  async listarTodosEmpregadores() {
    return await this.axios.get(`/listarTodosEmpregadores`);
  };

  async ativarInativarEmpregador(id: string) {
    return await this.axios.get(`/ativarInativarEmpregador?id=${id}`);
  };

  async listarEmpregadorPorId(id: string) {
    return await this.axios.get(`/listarEmpregadorPorId?id=${id}`);
  };

  async incluirAlterarEmpregador(dto: Empregador) {
    return await this.axios.post(`/incluirAlterarEmpregador`, dto);
  };

  async listarOrgaoPorEmpregador(id: string) {
    return await this.axios.get(`/orgao/listarPorEmpregador?id=${id}`);
  };

  async ativarInativarOrgao(id: string) {
    return await this.axios.get(`/orgao/ativarInativarOrgao?id=${id}`);
  };

  async incluirAlterarOrgao(dto: Orgao) {
    return await this.axios.post(`/orgao/incluirAlterarOrgao`, dto);
  };

  async processarArquivoLote(idEmpregador: string, arquivoDTO: ArquivoDTO) {
    return await this.axios.post(`/orgao/processarArquivoLote?idEmpregador=${idEmpregador}`, arquivoDTO);
  };
  
}