import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Esteira } from '../../../shared/model/esteira';
import Spinner from '../../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import EsteiraModeloService from '../../../services/esteiraModeloService';
import ConfirmModal from '../../../shared/customAlertConfirm';

function EsteiraComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [esteira, setEsteiras] = useState<Esteira[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);

  const esteiraModeloService: EsteiraModeloService = new EsteiraModeloService();

  const listarTodasEsteirasModelo = async () => {
    try {
      setLoading(true);
      const responseList = await esteiraModeloService.listarTodasEsteirasModelo();
      setEsteiras(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarEsteira = async (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
        await esteiraModeloService.ativarInativarEsteira(id);
        listarTodasEsteirasModelo();
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  useEffect(() => {
    listarTodasEsteirasModelo();
  }, []);


  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Esteira</h5>
        <Button
          onClick={() => navigate('/parametros-esteira/inclusao', { state: { id: null } })}
          style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
        >
          + Nova Esteira
        </Button>
      </div>
      {loading ? <Spinner loading={loading} /> : (
        <div className="table-responsive">
          <div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table">Código</th>
                  <th scope="col-xl" id="App-header-table">Nome</th>
                  <th scope="col" id="App-header-table">Alteração</th>
                  <th scope="col" id="App-header-table">Status</th>
                </tr>
              </thead>
              <tbody>
                {esteira.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.nomeEsteira}</td>
                    <td>
                      <Link to={`/parametros-esteira/alteracao`} state={{ idEsteira: item.id, nomeEst: item.nomeEsteira }}>
                        <ButtonAlterar />
                      </Link>
                    </td>

                    <td>
                      {item.ativo ?
                        <ButtonInativar onClick={() => ativarInativarEsteira(item.id, item.ativo)} />
                        :
                        <ButtonAtivar onClick={() => ativarInativarEsteira(item.id, item.ativo)} />
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
          </div>
        </div>
      )}
    </div>
  );
}

export default EsteiraComponent;