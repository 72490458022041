import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { OperacaoDTO } from '../../../shared/dto/operacaoDTO';
import { format } from 'date-fns';
import ScrollToTopButton from '../../../shared/scrollToTopButton';
import { OperacaoParcela } from '../../../shared/model/operacaoParcela';
import OperacaoParcelaService from '../../../services/operacaoParcelaService';
import OperacaoService from '../../../services/operacaoService';

function DetalheContratoComponent() {
  const location = useLocation();
  const { contrato } = location.state || {};
  const [botaoPagina, setBotaoPagina] = useState(1);
  const [loading, setLoading] = useState(false);

  const [contratoDetalhe, setContratoDetalhe] = useState<OperacaoDTO>();
  const [parcelasContrato, setParcelasContrato] = useState<OperacaoParcela[]>([]);

  const operacaoParcelaService: OperacaoParcelaService = new OperacaoParcelaService();
  const operacaoService: OperacaoService = new OperacaoService();

  const listarContrato = async () => {
    try {
      setLoading(true);
      const responseList = await operacaoService.listarContratoDetalhePorContrato(contrato);
      setContratoDetalhe(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const listarParcelasContrato = async () => {
    try {
      setLoading(true);
      const responseList = await operacaoParcelaService.listarParcelasPorContrato(contrato);
      const json = responseList.data;
      setParcelasContrato(json);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarContrato();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Detalhes do Contrato: {contrato}</h5>
          <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}
            onClick={() => window.history.back()}
          > Voltar
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <>
            <div>
              <Button style={{ backgroundColor: '#6c757d', borderColor: '#6c757d', color: 'white', width: '10%', height: '50px', marginLeft: '10px' }}
                onClick={() => setBotaoPagina(1)}>
                Contrato
              </Button>
              <Button style={{ backgroundColor: '#6c757d', borderColor: '#6c757d', color: 'white', width: '10%', height: '50px', marginLeft: '10px' }}
                onClick={() => { setBotaoPagina(2); listarParcelasContrato() }}>
                Parcelas
              </Button>
              <Button style={{ backgroundColor: '#6c757d', borderColor: '#6c757d', color: 'white', width: '15%', height: '50px', marginLeft: '10px' }}
                onClick={() => { setBotaoPagina(3) }}>
                Saldo Devedor
              </Button>
              <Button style={{ backgroundColor: '#6c757d', borderColor: '#6c757d', color: 'white', width: '15%', height: '50px', marginLeft: '10px' }}
                onClick={() => setBotaoPagina(4)}>
                Cessão
              </Button>
            </div>
            {botaoPagina === 1 ? (
              <>
                <div className="card" style={{ marginTop: '25px' }}>
                  <div className="card-body">
                    <div className="card-header">
                      <strong>Origem</strong>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Matriz</span>
                          <input type="text" value={contratoDetalhe?.matriz!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Promotora</span>
                          <input type="text" value={contratoDetalhe?.promotora!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Supervisor</span>
                          <input type="text" value={contratoDetalhe?.supervisor!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Operador</span>
                          <input type="text" value={contratoDetalhe?.operador!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Ponto de Venda</span>
                          <input type="text" value={contratoDetalhe?.pontoVenda!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-header">
                      <strong>Operação</strong>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Produto</span>
                          <input type="text" value={contratoDetalhe?.produto!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Tabela de Financiamento</span>
                          <input type="text" value={contratoDetalhe?.tabelaFinanciamento!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Empregador</span>
                          <input type="text" value={contratoDetalhe?.empregador!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Secretaria/UPAG</span>
                          <input type="text" value={contratoDetalhe?.orgao!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                        </div>
                      </div>*/}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-header">
                      <strong>Valores</strong>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Solicitado</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorFinanciado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor IOF</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorIof.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Principal</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorFinanciado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Parcela</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorParcela.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Líquido</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Bruto</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorBruto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Transf</span>

                          <input type="text" value={'R$ ' + '0,00'} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Valor Refin</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorRefin.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-header">
                      <strong>Taxas</strong>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa CL a.m.</span>
                          <input type="text" value={contratoDetalhe?.taxaClAm.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa AP a.m.</span>
                          <input type="text" value={'0,000'} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa CET a.m.</span>
                          <input type="text" value={contratoDetalhe?.taxaCetAm.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa Nominal a.m.</span>
                          <input type="text" value={'0,000'} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa CL a.a.</span>
                          <input type="text" value={contratoDetalhe?.taxaClAa.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa AP a.a.</span>
                          <input
                            type="text" value={'0,000'} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa CET a.a.</span>
                          <input type="text" value={contratoDetalhe?.taxaCetAa.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 })} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Taxa Nominal a.a.</span>
                          <input type="text" value={'0,000'} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-header">
                      <strong>Prazos</strong>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Quantidade Parcelas</span>
                          <input type="text" value={contratoDetalhe?.prazo} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Data Base</span>
                          <input type="text" className="form-control form-control-sm brds" readOnly
                            value={contratoDetalhe?.dtBase ? format(new Date(contratoDetalhe?.dtBase), 'dd/MM/yyyy') : ''}
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Primeiro Vencimento</span>
                          <input type="text" className="form-control form-control-sm brds" readOnly
                            value={contratoDetalhe?.dtPrimeiroVencimento ? format(new Date(contratoDetalhe?.dtPrimeiroVencimento), 'dd/MM/yyyy') : ''}
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }} />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>Último Vencimento</span>
                          <input type="text" className="form-control form-control-sm brds" readOnly
                            value={contratoDetalhe?.dtUltimoVencimento ? format(new Date(contratoDetalhe?.dtUltimoVencimento), 'dd/MM/yyyy') : ''}
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'left' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-header">
                      <strong>LIBERAÇÕES</strong>
                    </div>
                    <div className="row">
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>NÚMERO</span>
                          <input type="text" value={'1'} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <span style={{ textAlign: 'left' }}>BENEFICIÁRIO</span>
                          <input type="text" value={contratoDetalhe?.} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'right' }} />
                        </div>
                      </div> */}
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>CPF</span>
                          <input type="text" value={contratoDetalhe?.cpfCliente} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>NOME</span>
                          <input type="text" value={contratoDetalhe?.nomeCliente} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>BANCO</span>
                          <input type="text" value={contratoDetalhe?.banco} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>AGÊNCIA</span>
                          <input type="text" value={contratoDetalhe?.agencia} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>CONTA</span>
                          <input type="text" value={contratoDetalhe?.conta} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>DV</span>
                          <input type="text" value={contratoDetalhe?.dvConta} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <span style={{ textAlign: 'center' }}>VALOR</span>
                          <input type="text" value={'R$ ' + contratoDetalhe?.valorLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} className="form-control form-control-sm brds" readOnly
                            style={{ color: 'Black', backgroundColor: 'LightGrey', textAlign: 'center' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : botaoPagina === 2 ? (
              <div className="table-responsive">
                  <div style={{ width: '100%', borderWidth: '2px', marginTop: '10px' }}>
                    <table cellSpacing="2" cellPadding="4" rules="all" style={{ border: '3', color: 'Black', borderColor: '#999999', borderWidth: '3px', borderStyle: 'Solid', width: '100%' }} className="table table-bordered table-striped">
                      <tbody>
                        <tr className="GridHeaderStyle" style={{ backgroundColor: '#white', color: 'black', textAlign: 'center' }}>
                          <th scope="col" id="App-header-table">NÚMERO</th>
                          <th scope="col" id="App-header-table">VENCIMENTO</th>
                          <th scope="col" id="App-header-table">VALOR PARCELA</th>
                          <th scope="col" id="App-header-table">TIPO</th>
                          <th scope="col" id="App-header-table">MOVIMENTAÇÃO</th>
                          <th scope="col" id="App-header-table">VALOR PAGO</th>
                          <th scope="col" id="App-header-table">VALOR AMORTIZADO</th>
                          <th scope="col" id="App-header-table">VALOR JUROS</th>
                          <th scope="col" id="App-header-table">SALDO REMANESCENTE</th>
                        </tr>
                        {parcelasContrato.map((item) => (
                          <tr className="GridRowStyle">
                            <td style={{ textAlign: 'center' }}>{item.numeroParcela}</td>
                            <td style={{ textAlign: 'center' }}>{item.dtVencimento ? format(new Date(item.dtVencimento), 'dd/MM/yyyy') : ''}</td>
                            <td style={{ textAlign: 'center' }}>R$ {item.valorParcela != null ? item.valorParcela!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                            <td style={{ textAlign: 'left' }}>{(item.tipoMovimentacao)?.replace(/_/g, ' ')}</td>
                            <td style={{ textAlign: 'center' }}>{item.dtMovimentacao ? format(new Date(item.dtMovimentacao), 'dd/MM/yyyy') : ''}</td>
                            <td style={{ textAlign: 'center' }}>R$ {item.valorPago != null ? item.valorPago!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                            <td style={{ textAlign: 'center' }}>R$ {item.valorAmortizado != null ? item.valorAmortizado!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                            <td style={{ textAlign: 'center' }}>R$ {item.valorJuros != null ? item.valorJuros!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}</td>
                            <td style={{ textAlign: 'center' }}>R$ {(item.valorParcela! - item.valorPago!).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
            ) : botaoPagina === 3 ? (
              <div className="card" style={{ marginTop: '25px' }}>
                <div className="card-header">
                  <strong>Saldo Devedor</strong>
                </div>
                <span style={{ backgroundColor: 'LightGrey' }}>Nenhum registro localizado!</span>
              </div>
            ) : botaoPagina === 4 ? (
              <div className="card" style={{ marginTop: '25px' }}>
                <div className="card-header">
                  <strong>Cessão</strong>
                </div>
                <span style={{ backgroundColor: 'LightGrey' }}>Nenhum registro localizado!</span>
              </div>
            ) : null}
            <ScrollToTopButton />
          </>
        )}
      </div >
    </>
  );
}

export default DetalheContratoComponent;