const EstadosCivis = [
  { value: '', label: '' },
  { value: 'SOLTEIRO', label: 'Solteiro' },
  { value: 'CASADO', label: 'Casado' },
  { value: 'DIVORCIADO', label: 'Divorciado' },
  { value: 'DESQUITADO', label: 'Viuvo' },
  { value: 'VIUVO', label: 'Desquitado' },
];

const getLabelEstadosCivis = (value: string) => {
  const estadoCivil = EstadosCivis.find(est => est.value === value);
  return estadoCivil ? estadoCivil.label : '';
};

export { EstadosCivis, getLabelEstadosCivis }