import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import bpoimg from '../../../resources/logo/bpo.png';
import UsuarioExternoService from '../../services/usuarioExternoService';
import bpoImagem from '../../../resources/logo/imgBackGround.webp'
import Botao from '../../shared/botao';
import styled from 'styled-components';
import Rodape from '../../shared/rodape';
import CustomAlert from '../../shared/customAlert';

const usuarioExternoService = new UsuarioExternoService();

const LoginComponent = () => {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<{ login: string; senha: string }>({
    login: '',
    senha: '',
  });
  const navigate = useNavigate();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await usuarioExternoService.login(form);
      if (response === true) {
        navigate('/home');
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    usuarioExternoService.logout();
  }, []);

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      <div style={{
        backgroundImage: `url(${bpoImagem})`, backgroundSize: 'cover', backgroundPosition: 'center',
        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, filter: 'blur(8px)', opacity: 0.6
      }}
      />
      <Container fluid className="d-flex justify-content-center align-items-center vh-100">
        <Row className="w-100" >
          <Col md={4} className="mx-auto" style={{ display: 'flex', padding: '4rem', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', justifyContent: 'center', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', gap: '5px 0px' }}>
            <div className="text-center mb-4">
              <Image width={"280px"} src={bpoimg} fluid />
            </div>
            <Form onSubmit={handleSubmit} >
              <Form.Group className="md-6">
                <StyledInput type="text" placeholder="Digite o seu usuario" name="login" value={form.login} onChange={handleChange} className="mb-3" />
              </Form.Group>
              <Form.Group controlId="senha">
                <StyledInput type="password" placeholder="Digite a sua senha" name="senha" value={form.senha} onChange={handleChange} className="mb-5" />
              </Form.Group>
              <Botao type="submit" text="Entrar" onClick={handleSubmit} disabled={loading === true} />
              <div className="mt-3" >
                <Button style={{ color: 'black', textDecoration: 'none' }}
                  variant="link"
                  onClick={() => setAlert({ message: 'Entre em contato com suporte!', type: 'warning' })}
                >
                  <EsqueceuSenha>
                    Esqueceu sua senha?
                  </EsqueceuSenha>
                </Button>
              </div>
            </Form>
            <Rodape />
          </Col>
        </Row>
      </Container>
    </>

  );
};

export default LoginComponent;

const StyledInput = styled(Form.Control)`
  color: black;
  font-size: 18px; 
  background-color: transparent;
  border: 2px solid #6a6a6a;
  border-radius: 8px;
  padding: 16px 20px;
  width: 100%;
  height: 50px; 
  !box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  
  @media (max-width: 576px) {
    font-size: 12px; 
    padding: 5px 15px; 
    width: 120%; 
    height: 45px;
  }
`;

const EsqueceuSenha = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px 20px;
  align-items: center';
  border-color: white;
  color: black;
  background-color: white;
  font-size: 16px; 
  text-decoration: none;
  margin-left: 50px;

  @media (max-width: 576px) {
    font-size: 12px; 
    width: 120%; 
    height: 45px; 
    margin-left: 15px;
}`