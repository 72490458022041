import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComunicadoDTO } from '../../shared/dto/comunicadoDTO';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ComunicadoService from '../../services/comunicadoService';

function ComunicadosVigentesComponent() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [comunicados, setComunicados] = useState<ComunicadoDTO[]>([]);

  const comunicadoService: ComunicadoService = new ComunicadoService();

  const listarTodosComunicadosVigentes = async () => {
    try {
      setLoading(true);
      const responseList = await comunicadoService.listarTodosComunicadosVigentes();
      const jsonList = responseList.data;
      
      setComunicados(jsonList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const downloadArquivo = async (caminhoArquivo: string, nomeArquivo: string) => {
    setLoading(true);
    try {
      await comunicadoService.downloadComunicadoPorCaminhoArquivo(caminhoArquivo)
        .then((resp) => {
          const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
          const urlBlob = window.URL.createObjectURL(blob);
          const linkDownload = document.createElement('a');
          linkDownload.href = urlBlob;
          linkDownload.download = nomeArquivo;
          document.body.appendChild(linkDownload);
          linkDownload.click();
          document.body.removeChild(linkDownload);
          window.URL.revokeObjectURL(urlBlob);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  useEffect(() => {
    listarTodosComunicadosVigentes();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Comunicados Vigentes</h5>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table" style={{ width: '25%' }} >Tipo Comunicado</th>
                  <th scope="col" id="App-header-table" style={{ width: '39%' }} >Nome</th>
                  <th scope="col" id="App-header-table" style={{ textAlign: 'center', width: '20%' }} >Data Fim Vigência</th>
                  <th scope="col" id="App-header-table" style={{ textAlign: 'center', width: '8%' }} >Visualizar</th>
                  <th scope="col" id="App-header-table" style={{ textAlign: 'center', width: '8%' }} >Download</th>
                </tr>
              </thead>
              <tbody>
                {comunicados.map((item) => (
                  <tr>
                    <td>{item.tipoComunicado}</td>
                    <td>{item.nomeComunicado}</td>
                    <td style={{ textAlign: 'center' }}>{item.dtFimVigencia ? format(new Date(item.dtFimVigencia), 'dd/MM/yyyy') : ''}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Button style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} 
                        onClick={() => navigate('/comunicados-visualizacao', { state: { id: item.id } })}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {item.download ? 
                        <Button style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} onClick={() => downloadArquivo(item.caminhoArquivo, item.nomeArquivo)}>
                          <FontAwesomeIcon icon={faDownload} />
                        </Button> : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default ComunicadosVigentesComponent;