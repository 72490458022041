const TiposResidencia = [
  { value: '', label: '' },
  { value: 'PROPRIA', label: 'Própria' },
  { value: 'ALUGADA', label: 'Alugada' },
  { value: 'DE_FAMILIARES', label: 'De Familiares' },
  { value: 'DA_EMPRESA', label: 'Da Empresa' },
  { value: 'FINANCIADA', label: 'Financiada' },
  { value: 'HOTEL', label: 'Hotel' },
];

const getLabelTiposResidencia = (value: string) => {
  const tipoResidencia = TiposResidencia.find(x => x.value === value);
  return tipoResidencia ? tipoResidencia.label : '';
};

export { TiposResidencia, getLabelTiposResidencia }