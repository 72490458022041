import createAxiosInstance from './axiosConfig';
import { EsteiraDTO } from '../shared/dto/esteiraDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/esteiraController');

export default class EsteiraService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarEsteiraProcessoPorIdProposta(idProposta: string) {
    return await this.axios.get(`/listarEsteiraProcessoPorIdProposta?idProposta=${idProposta}`);
  };

  async incluirAlterarEsteira(dto: EsteiraDTO[]) {
    return await this.axios.post(`/incluirAlterarEsteira`, dto);
  };

  async listarEsteiraPorIdEsteiraModelo(idEsteira: string) {
    return await this.axios.get(`/listarEsteiraPorIdEsteiraModelo?idEsteiraModelo=${idEsteira}`);
  };

}