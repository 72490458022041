import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../shared/spinner';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { ParcelaCessaoBaixadaDTO } from '../../../shared/dto/parcelaCessaoBaixadaDTO';
import { format } from 'date-fns';
import CessaoParcelaService from '../../../services/cessaoParcelaService';
import CustomAlert from '../../../shared/customAlert';

function RemessaoLiquidacaoComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [loading, setLoading] = useState(true);
  const [parcelas, setParcelas] = useState<ParcelaCessaoBaixadaDTO[]>([]);
  
  const cessaoParcelaService: CessaoParcelaService = new CessaoParcelaService();

  const listarParcelasBaixadas = async () => {
    try {
      setLoading(true);
      const responseList = await cessaoParcelaService.listarParcelasBaixadas();
      setParcelas(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const solicitarLiquidacao = async () => {
    try {
      setLoading(true);
      const response = await cessaoParcelaService.solicitarLiquidacaoParcelas(parcelas);
      const contentType = response.headers['content-type'];

      if (contentType === 'text/plain') {
        const errorText = await response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Parcelas_Cessao_Baixadas.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response && err.response.status === 500 && err.response.data instanceof Blob) {
        const errorText = await err.response.data.text();
        setAlert({ message: errorText, type: 'warning' });
      } else {
        setAlert({ message: 'Ocorreu um erro ao gerar o relatório', type: 'error' });
      }
      console.error(err);
    }
  };

  useEffect(() => {
    listarParcelasBaixadas();
  }, []);

  return (
    <>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex' }}>
          <h5>BackOffice &gt; Cessão &gt; Remessa de Liquidação</h5>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div style={{ maxHeight: '400px' }} className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="GridHeaderStyle" style={{ backgroundColor: 'white', color: 'black', position: 'sticky', top: '0' }} >
                    <th scope="col" id="App-header-table">Contrato</th>
                    <th scope="col" id="App-header-table">Parcela</th>
                    <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Valor Parcela</th>
                    <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Valor Cedido</th>
                    <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Data Vencimento</th>
                    <th style={{ textAlign: 'center' }} scope="col" id="App-header-table">Data Movimentação</th>
                  </tr>
                </thead>
                <tbody>
                  {parcelas.map((item) => (
                    <tr>
                      <td>{item.numeroContrato}</td>
                      <td>{item.numeroParcela}</td>
                      <td style={{ textAlign: 'center' }}>R$ {item.valorParcela!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'center' }}>R$ {item.valorCedido!.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ textAlign: 'center' }}>{item.dataVencimento ? format(new Date(item.dataVencimento), 'dd/MM/yyyy') : ''}</td>
                      <td style={{ textAlign: 'center' }}>{item.dataMovimentacao ? format(new Date(item.dataMovimentacao), 'dd/MM/yyyy') : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <Button onClick={solicitarLiquidacao}
          style={{ backgroundColor: '#3d6062', borderColor: '#3d6062', marginTop: '20px', color: 'white' }}
        >
          <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
          Solicitar Liquidação
        </Button>
      </div>
    </>
  );
}

export default RemessaoLiquidacaoComponent;