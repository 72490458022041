import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Matriz } from '../../../shared/model/matriz';
import Spinner from '../../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import MatrizService from '../../../services/matrizService';
import ConfirmModal from '../../../shared/customAlertConfirm';

function MatrizComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [matriz, setMatriz] = useState<Matriz[]>([]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);


  const matrizService: MatrizService = new MatrizService();

  const listarTodasMatrizes = async () => {
    try {
      setLoading(true);
      const responseList = await matrizService.listarTodasMatrizes();
      const jsonListMatriz = responseList.data;

      setMatriz(jsonListMatriz);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarMatriz = (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
        await matrizService.ativarInativarMatriz(id);
        listarTodasMatrizes();
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  useEffect(() => {
    listarTodasMatrizes();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Matriz</h5>
          <Button
            onClick={() => navigate('/origens-matriz/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Nova Matriz
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table">Código</th>
                  <th scope="col" id="App-header-table">Razão Social</th>
                  <th scope="col" id="App-header-table">CNPJ</th>
                  <th scope="col" id="App-header-table">Cidade</th>
                  <th scope="col" id="App-header-table">Telefone</th>
                  <th scope="col" id="App-header-table">Email</th>
                  <th scope="col" id="App-header-table">Alteração</th>
                  <th scope="col" id="App-header-table">Status</th>
                </tr>
              </thead>
              <tbody>
                {matriz.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.nome}</td>
                    <td>{item.cnpj}</td>
                    <td>{item.cidade}</td>
                    <td>{item.foneComercial}</td>
                    <td>{item.email}</td>
                    <td>
                      <Link to={`/origens-matriz/alteracao`} state={{ id: item.id }}>
                        <ButtonAlterar />
                      </Link>
                    </td>
                    <td>
                      {item.ativo ? (
                        <ButtonInativar onClick={() => ativarInativarMatriz(item.id, item.ativo)} />
                      ) : (
                        <ButtonAtivar onClick={() => ativarInativarMatriz(item.id, item.ativo)} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
          </div>
        )}
      </div>
    </>
  );
}

export default MatrizComponent;