import { useEffect, useState } from "react";
import ComunicadoService from "../services/comunicadoService";
import { ComunicadoDTO } from "../shared/dto/comunicadoDTO";
import CustomAlertModalComunicado from "../shared/customAlertComunicado";
import { UUID } from "crypto";


function Home() {
  const [alertQueue, setAlertQueue] = useState<Array<{ message: string, type: 'warning', imagemBase64?: string, codigoComunicado: UUID }>>([]);
  const [currentAlert, setCurrentAlert] = useState<{ message: string, type: 'warning', imagemBase64?: string, codigoComunicado: UUID } | null>(null);
  const comunicadoService: ComunicadoService = new ComunicadoService();

  const handleCloseAlert = async (codigoComunicado: UUID) => {
    try{
      await comunicadoService.confirmacaoLeituraComunicado(codigoComunicado);
    }catch (err) {
      console.error(err);
    }
    if (alertQueue.length > 0) {
      setCurrentAlert(alertQueue[0]);
      setAlertQueue(prev => prev.slice(1)); 
    } else {
      setCurrentAlert(null); 
    }
  };
  
  const exibirComunicadoUsuario = async () => {
    try {
      await comunicadoService.exibirComunicadoUsuario()
        .then(resp => {
          if (resp.data.length > 0) {
            console.log(resp.data);
            const alertList = resp.data.map((c: ComunicadoDTO) => ({ message: c.descricao, type: 'warning', imagemBase64: c.arquivoBase64, codigoComunicado: c.codigo }));
            setAlertQueue(alertList); 
            if (!currentAlert) {
              setCurrentAlert(alertList[0]);
              setAlertQueue(alertList.slice(1)); 
            }
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    exibirComunicadoUsuario();
  }, []);

  return (
    <div style={{ height: '80vh' }}>
      {currentAlert && (
      <CustomAlertModalComunicado message={currentAlert.message} type={currentAlert.type} imagemBase64={currentAlert.imagemBase64} onClose={() => handleCloseAlert(currentAlert.codigoComunicado)} /> )}
    </div>
  );
}


export default Home;