const NaturezaOcupacoes = [
  { value: '', label: '' },
  { value: 'ASSALARIADO', label: 'Assalariado' },
  { value: 'AUTONOMO', label: 'Autônomo' },
  { value: 'PROFISSIONAL_LIBERAL', label: 'Profissional Liberal' },
  { value: 'EMPRESARIO', label: 'Empresário' },
  { value: 'APOSENTADO', label: 'Aposentado' },
  { value: 'PENSIONISTA', label: 'Pensionista' },
  { value: 'FUNCIONARIO_PUBLICO', label: 'Funcionário Público' },
  { value: 'AGRICULTORES_PECUARISTAS', label: 'Agricultores/Pecuaristas' },
  { value: 'RELIGIOSO', label: 'Religioso' },
  { value: 'CAPITALISTA', label: 'Capitalista' },
];

const getLabelNaturezaOcupacoes = (value: string) => {
  const naturezaOcupacao = NaturezaOcupacoes.find(x => x.value === value);
  return naturezaOcupacao ? naturezaOcupacao.label : '';
};

export { NaturezaOcupacoes, getLabelNaturezaOcupacoes }