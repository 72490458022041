import 'bootstrap/dist/css/bootstrap.css';
import { cnpj as cnpjValidacao } from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import { EnderecoDTO } from '../../../shared/dto/enderecoDTO';
import { MatrizDTO } from '../../../shared/dto/matrizDTO';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import MatrizService from '../../../services/matrizService';
import ViaCepService from '../../../services/viaCepService';
import CustomAlert from '../../../shared/customAlert';

function MatrizInclusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [emails, setEmails] = useState('');
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');

  const [loading, setLoading] = useState(false);

  const matrizService: MatrizService = new MatrizService();
  const viaCepService: ViaCepService = new ViaCepService();

  const listarMatrizPorId = async () => {
    setLoading(true);
    try {
      const response = await matrizService.listarMatrizPorId(id!);
      const jsonMatriz = response.data;
      

      setNome(jsonMatriz.nome);
      setCnpj(jsonMatriz.cnpj);
      setEmails(jsonMatriz.email);
      setTelefoneComercial(jsonMatriz.foneComercial);
      setTelefoneCelular(jsonMatriz.foneCelular);

      setCep(jsonMatriz.cep);
      setLogradouro(jsonMatriz.endereco);
      setNumero(jsonMatriz.numeroEndereco);
      setComplemento(jsonMatriz.complemento);
      setBairro(jsonMatriz.bairro);
      setLocalidade(jsonMatriz.cidade);
      setUf(jsonMatriz.uf);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };


  const handleSubmit = async (event: any) => {
    if (!cnpjValidacao.isValid(cnpj)) {
      setAlert({ message: 'CNPJ Inválido', type: 'warning' });
      return;
    } else {
      event.preventDefault();
      let endereco : EnderecoDTO = new EnderecoDTO();
      endereco.cep = cep;
      endereco.logradouro = logradouro;
      endereco.numero = numero;
      endereco.complemento = complemento;
      endereco.bairro = bairro;
      endereco.uf = uf;
      endereco.localidade = localidade;
      let matrizDTO: MatrizDTO = new MatrizDTO();
      matrizDTO.id = id!;
      matrizDTO.nome = nome;
      matrizDTO.cnpj = cnpj;
      matrizDTO.email = emails;
      matrizDTO.enderecoDTO = endereco;
      matrizDTO.telefoneComercial = telefoneComercial;
      matrizDTO.telefoneCelular = telefoneCelular;

      try {
        await matrizService.incluirAlterarMatriz(matrizDTO!);
        setLoading(false);
        setAlert({ message: 'Matriz salva com sucesso!', type: 'success' });
      } catch (err: any) {
        setLoading(false);
        setAlert({ message: err.response.data, type: 'error' });
        console.error(err);
      }
    }
  };

  const limparCampos = () => {
    setNome('');
    setCnpj('');
    setEmails('');
    setTelefoneComercial('');
    setTelefoneCelular('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setLocalidade('');
    setUf('');
  }

  const viaCep = async (cep: string) => {
    if (cep !== undefined && cep !== '' && cep.length === 10) {
      console.log(cep)
      setLoading(true);
      const cepWithoutMask = cep.replace(/\D/g, '');
      try {
        const response = await viaCepService.viaCep(cepWithoutMask);

        const jsonViaCep = response.data;
        setLogradouro(jsonViaCep.logradouro);
        setLocalidade(jsonViaCep.localidade);
        setUf(jsonViaCep.uf);
        setBairro(jsonViaCep.bairro);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarMatrizPorId();
    }
  }, []);

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=> { setAlert(null); if (alert.type === 'success') {window.history.back();} }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Origens &gt; Matriz &gt; Inclusão</h5> : (
                <h5>Origens &gt; Matriz &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Razão Social <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>CNPJ <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Lista de E-mails (separados por ';') <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={emails} onChange={(e) => setEmails(e.target.value)} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {loading ? <Spinner loading={loading} /> : false}
            <div className="col-md-2">
              <label>CEP <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99.999-999" type="text" className="form-control" required
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                onBlur={(e) => viaCep(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label>Endereço <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label>Número <span style={{ color: 'red' }}>*</span></label>
              <input type="number" className="form-control" required value={numero} onChange={(e) => setNumero(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label>Bairro <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={bairro} onChange={(e) => setBairro(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Complemento</label>
              <input type="text" className="form-control" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Cidade <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={localidade} onChange={(e) => setLocalidade(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Estado <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="aa" type="text" required className="form-control" value={uf} onChange={(e) => setUf(e.target.value.toUpperCase())} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            <div className="col-md">
              <label className="form-label">Telefone Comercial <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)9999-9999" type="text" className="form-control" value={telefoneComercial} onChange={(e) => setTelefoneComercial(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-md">
              <label className="form-label">Telefone Celular <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)99999-9999" type="text" className="form-control" value={telefoneCelular} onChange={(e) => setTelefoneCelular(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Matriz' : 'Salvar Matriz'}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default MatrizInclusaoAlteracao;