import React from 'react';
import styled from 'styled-components';

interface ConfirmModalProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ConfirmModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
`;

const ConfirmButton = styled.button`
  padding: 10px 20px;
  background-color: #28a745; /* Verde */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
  &:hover {
    background-color: #218838;
  }
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  background-color: #dc3545; /* Vermelho */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
  &:hover {
    background-color: #c82333;
  }
`;

const ConfirmModal: React.FC<ConfirmModalProps> = ({ message, onConfirm, onCancel }) => {
  return (
    <ConfirmModalOverlay>
      <ConfirmModalContainer>
        <h3>Confirmação</h3>
        <p>{message}</p>
        <div>
          <ConfirmButton onClick={onConfirm}>Sim</ConfirmButton>
          <CancelButton onClick={onCancel}>Não</CancelButton>
        </div>
      </ConfirmModalContainer>
    </ConfirmModalOverlay>
  );
};

export default ConfirmModal;
