import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { FinanciamentoEmpregadorDTO } from '../../../shared/dto/financiamentoEmpregadorDTO';
import FinanciamentoEmpregadorService from '../../../services/financiamentoEmpregadorService';
import CustomAlert from '../../../shared/customAlert';

function FinanciamentoEmpregador() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { idFinanciamento, codigoFinanciamento, nomeFinanciamento } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [financiamentoEmpregadores, setFinanciamentoEmpregadores] = useState<FinanciamentoEmpregadorDTO[]>([]);
  const [selecionarTudo, setSelecionarTudo] = useState(false);

  const financiamentoEmpregadorService: FinanciamentoEmpregadorService = new FinanciamentoEmpregadorService();

  const listarEmpregadorPorFinancinamento = async () => {
    try {
      const response = await financiamentoEmpregadorService.listarFinanciamentoEmpregador(idFinanciamento);
      setFinanciamentoEmpregadores(response.data);

    } catch (err) {
      console.error(err);
    }
  };

  const handleAtivoChange = (id: any) => {
    setFinanciamentoEmpregadores((prevData) =>
      prevData.map((item) =>
        item.empregador === id ? {
          ...item, ativo: !item.ativo
        } : item
      )
    );
  };

  const selecionarDeselecionarTodos = (marcar: boolean) => {
    setSelecionarTudo(!selecionarTudo);
    setFinanciamentoEmpregadores((prevData) =>
      prevData.map((item) => ({ ...item, ativo: marcar }))
    );
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await financiamentoEmpregadorService.incluirAlterarFinanciamentoEmpregador(idFinanciamento, financiamentoEmpregadores)
        .then(() => setLoading(false));

      console.log(response)
      setAlert({ message: 'Atualização realizada com sucesso!', type: 'success' });
    } catch (err) {
      setLoading(false);
      setAlert({ message: 'Ocorreu um erro ao atualizar listar!', type: 'error' });
      console.error(err);
    }
  }

  useEffect(() => {
    listarEmpregadorPorFinancinamento();
  }, []);

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Tabela de Financiamento &gt; Empregadores</h5>
        <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
          Voltar
        </Button>
      </div>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=> { setAlert(null); if (alert.type === 'success') {window.history.back();} }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <>
          <div className="row">
            <div className="col-md-1">
              <label>ID</label>
              <text className="form-control" display={'none'}>{idFinanciamento}</text>
            </div>
            <div className="col-md-2">
              <label>Código</label>
              <text className="form-control" display={'none'}>{codigoFinanciamento}</text>
            </div>
            <div className="col-md-3">
              <label>Nome</label>
              <text className="form-control" display={'none'}>{nomeFinanciamento}</text>
            </div>
          </div>
          <br></br>
          <div>
            {selecionarTudo ? (
              <>
                <input type="checkbox" checked={selecionarTudo} onChange={() => selecionarDeselecionarTodos(false)} />
                <label>  Deselecionar Tudo</label>
              </>
            ) : (
              <>
                <input type="checkbox" checked={selecionarTudo} onChange={() => selecionarDeselecionarTodos(true)} />
                <label>  Selecionar Tudo</label>
              </>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="table-responsive">
              <div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col" id="App-header-table">Ativo</th>
                      <th scope="col" id="App-header-table">Empregadores</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financiamentoEmpregadores.map((item) => (
                      <tr>
                        <td><input type="checkbox" checked={item.ativo} onChange={() => handleAtivoChange(item.empregador)} /></td>
                        <td>{item.empregador} - {item.nomeEmpregador}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Atualizar Listar</button>
              </div>
            </div>
          </form>
        </>


      )}
    </div>
  );
}

export default FinanciamentoEmpregador;