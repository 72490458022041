import createAxiosInstance from './axiosConfig';
import { RelatoriosDTO } from "../shared/dto/relatoriosDTO";
import { FiltroUsuarioDTO } from "../shared/dto/filtroUsuarioDTO";
import { FiltroComunicadoDTO } from '../shared/dto/filtroComunicadoDTO';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/arquivoController');

export default class ArquivoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTiposArquivos(processamento: any) {
    return await this.axios.get(`/listarTiposArquivos?processamento=${processamento}`);
  };

  async listarArquivosLote(tipoArq: string) {
    return await this.axios.get(`/listarArquivosLote?tipoArquivo=${tipoArq}`);
  };

  async downloadModeloLote(tipoArquivoProcessamento: string) {
    return await this.axios.get(`/downloadModeloLote?tipoArquivo=${tipoArquivoProcessamento}`, {
      responseType: 'blob',
    });
  };

  async downloadArquivoLote(caminhoArquivo: string) {
    return await this.axios.get(`/downloadArquivoLote?caminhoArquivo=${caminhoArquivo}`, {
      responseType: 'blob',
    });
  };

  async listarArquivoLoteDetalhe(idArquivo: string) {
    return await this.axios.get(`/listarArquivoLoteDetalhe?idArquivo=${idArquivo}&tipoArquivo=BAIXA_LOTE`);
  };

  async gerarRelatorioContratosCedidos(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioContratosCedidos`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioDeVencimento(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioDeVencimento`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioDeMovimentacaoFinanceira(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioDeMovimentacaoFinanceira`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioOperacaoRealizada(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioOperacaoRealizada`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioParcelasCedidas(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioParcelasCedidas`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioCliente(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioCliente`, dto, {
      responseType: 'blob',
    });
  };
  
  async listarOrigemGenerica(tipoOrigem: string) {
    return await this.axios.get(`/listarOrigemGenerica?tipoOrigem=${tipoOrigem}`);
  };

  async gerarRelatorioOrigensCadastradas(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioOrigensCadastradas`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioProducaoAnalitico(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioProducaoAnalitico`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioProducaoSintetico(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioProducaoSintetico`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioExtratoMensal(dto: RelatoriosDTO) {
    return await this.axios.post(`/gerarRelatorioExtratoMensal`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioUsuarios(dto: FiltroUsuarioDTO) {
    return await this.axios.post(`/gerarRelatorioUsuarios`, dto, {
      responseType: 'blob',
    });
  };

  async gerarRelatorioConfirmacaoComunicados(dto: FiltroComunicadoDTO) {
    return await this.axios.post(`/gerarRelatorioConfirmacaoComunicados`, dto, {
      responseType: 'blob',
    });
  };

  async downloadArquivoCadastroOrgaos() {
    return await this.axios.get(`/downloadArquivoCadastroOrgaos`, {
      responseType: 'blob',
    });
  };
  
}