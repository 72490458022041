import { Cessao } from "../model/cessao";
import { Empregador } from "../model/empregador";
import { Fidc } from "../model/fidc";
import { HistoricoFinanceiro } from "../model/historicoFinanceiro";
import { Promotora } from "../model/promotora";

export class RelatoriosDTO {
  public empregadores!: Array<Empregador>;
  public promotoras!: Array<Promotora>;
  public dtBase1!: Date;
  public dtBase2!: Date;

  public tiposMovimento!: string[]
  public historicosFinanceiros!: Array<HistoricoFinanceiro>;
  public fidcs!: Array<Fidc>;
  public cessoes!: Array<Cessao>;

  public dtCessao1!: Date;
  public dtCessao2!: Date;

  public tipoOrigem!: string;
  public tipoOrigemIds!: Array<string>;
  public tipoRelatorioAnalitico!: string;

  public situacaoEsteira!: String[];
}