import { FiltroComunicadoDTO } from '../shared/dto/filtroComunicadoDTO';
import createAxiosInstance from './axiosConfig';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/comunicadoConfirmacaoController');

export default class ComunicadoConfirmacaoService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosComunicadosConfirmacao(currentPage: number, max: number, filtro: FiltroComunicadoDTO) {
    return await this.axios.post(`/listarTodosComunicadosConfirmacao?page=${currentPage}&max=${max}`, filtro);
  };

}