import 'bootstrap/dist/css/bootstrap.css';
import { cnpj as cnpjValidacao, cpf as cpfValidacao } from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import { EnderecoDTO } from '../../../shared/dto/enderecoDTO';
import { PontoVendaDTO } from '../../../shared/dto/pontoVendaDTO';
import { Operador } from '../../../shared/model/operador';
import Spinner from '../../../shared/spinner';
import { Button } from 'react-bootstrap';
import OperadorService from '../../../services/operadorService';
import PontoVendaService from '../../../services/pontoVendaService';
import ViaCepService from '../../../services/viaCepService';
import CustomAlert from '../../../shared/customAlert';

function PontoVendaInclusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [operador, setOperador] = useState('');
  const [operadores, setOperadores] = useState<Operador[]>([]);
  const [nome, setNome] = useState('');
  const [selectCpfCnpj, setSelectCpfCnpj] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [porte, setPorte] = useState('')
  const [situacaoBanco, setSituacaoBanco] = useState('')
  const [observacao, setObservacao] = useState('')
  const [pvBalcao, setPvBalcao] = useState<boolean>(false)
  const [bloqueiaCpf, setBloqueiaCpf] = useState<boolean>(false)
  const [emails, setEmails] = useState('');
  const [telefoneComercial, setTelefoneComercial] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [localidade, setLocalidade] = useState('');
  const [uf, setUf] = useState('');

  const [loading, setLoading] = useState(false);

  const operadorService: OperadorService = new OperadorService();
  const pontoVendaService: PontoVendaService = new PontoVendaService();
  const viaCepService: ViaCepService = new ViaCepService();

  const tipoPorte = [
    { value: 'Pequeno Porte', label: 'Pequeno Porte' },
    { value: 'Médio Porte', label: 'Médio Porte' },
    { value: 'Grande Porte', label: 'Grande Porte' },
  ]

  const tipoSituacaoBanco = [
    { value: 'Prospecção', label: 'Prospecção' },
    { value: 'Em análise', label: 'Em análise' },
    { value: 'Pendente', label: 'Pendente' },
    { value: 'Reprovado', label: 'Reprovado' },
    { value: 'Cadastrada', label: 'Cadastrada' },
  ]

  const listarOperadores = async () => {
    try {
      const response = await operadorService.listarTodosOperadoresAtivos();
      if (tipo === 'inclusao') {
        setOperador(response.data[0].id.toString());
      }
      setOperadores(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const listarPontoVendaPorId = async () => {
    setLoading(true);
    try {
      const response = await pontoVendaService.listarPontoVendaPorId(id);
      const jsonPontoVenda = response.data;


      if (jsonPontoVenda.cpfCnpj.length === 18) {
        setSelectCpfCnpj('cnpj');
      } else {
        setSelectCpfCnpj('cpf');
      }

      setOperador(jsonPontoVenda.idOperador.toString()!);
      setNome(jsonPontoVenda.nome);
      setCpfCnpj(jsonPontoVenda.cpfCnpj);
      setEmails(jsonPontoVenda.email);
      setPorte(jsonPontoVenda.porte);
      setSituacaoBanco(jsonPontoVenda.situacaoBanco);
      setObservacao(jsonPontoVenda.observacao);
      setPvBalcao(jsonPontoVenda.pvBalcao);
      setBloqueiaCpf(jsonPontoVenda.bloqueiaCpf);
      setTelefoneComercial(jsonPontoVenda.foneComercial);
      setTelefoneCelular(jsonPontoVenda.foneCelular);

      setCep(jsonPontoVenda.cep);
      setLogradouro(jsonPontoVenda.endereco);
      setNumero(jsonPontoVenda.numeroEndereco);
      setComplemento(jsonPontoVenda.complemento);
      setBairro(jsonPontoVenda.bairro);
      setLocalidade(jsonPontoVenda.cidade);
      setUf(jsonPontoVenda.uf);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let validacao = false;

    if (selectCpfCnpj === 'cpf') {
      if (!cpfValidacao.isValid(cpfCnpj)) {
        setAlert({ message: 'CPF Inválido', type: 'warning' });
        return;
      } else {
        validacao = true;
      }
    } else {
      if (!cnpjValidacao.isValid(cpfCnpj)) {
        setAlert({ message: 'CNPJ Inválido', type: 'warning' });
        return;
      } else {
        validacao = true;
      }
    }

    if (validacao) {
      setLoading(true);
      let endereco: EnderecoDTO = new EnderecoDTO();
      endereco.cep = cep;
      endereco.logradouro = logradouro;
      endereco.numero = numero;
      endereco.complemento = complemento;
      endereco.bairro = bairro;
      endereco.uf = uf;
      endereco.localidade = localidade;
      let pontoVendaDTO: PontoVendaDTO = new PontoVendaDTO();
      pontoVendaDTO.operador = operador;
      pontoVendaDTO.id = id;
      pontoVendaDTO.nome = nome;
      pontoVendaDTO.cpfCnpj = cpfCnpj;
      pontoVendaDTO.email = emails;
      pontoVendaDTO.porte = porte;
      pontoVendaDTO.situacaoBanco = situacaoBanco;
      pontoVendaDTO.observacao = observacao;
      pontoVendaDTO.pvBalcao = pvBalcao;
      pontoVendaDTO.bloqueiaCpf = bloqueiaCpf;
      pontoVendaDTO.enderecoDTO = endereco;
      pontoVendaDTO.telefoneComercial = telefoneComercial;
      pontoVendaDTO.telefoneCelular = telefoneCelular;

      try {
        await pontoVendaService.incluirAlterarPontoVenda(pontoVendaDTO);
        setLoading(false);
        setAlert({ message: 'Ponto de Venda salvo com sucesso!', type: 'success' });
      } catch (err: any) {
        setLoading(false);
        setAlert({ message: err.response.data, type: 'error' });
        console.error(err);
      }
    }
  };

  const checkCpfCnpj = (option: any) => {
    setSelectCpfCnpj(option);

  };

  const checkboxPvBalcao = () => {
    setPvBalcao(!pvBalcao);
  };

  const checkboxBloqueiaCpf = () => {
    setBloqueiaCpf(!bloqueiaCpf);
  };

  const limparCampos = () => {
    setNome('');
    setCpfCnpj('');
    setEmails('');
    setTelefoneComercial('');
    setTelefoneCelular('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setLocalidade('');
    setUf('');
  };

  const viaCep = async (cep: string) => {
    if (cep !== undefined && cep !== '' && cep.length === 10) {
      console.log(cep)
      setLoading(true);
      const cepWithoutMask = cep.replace(/\D/g, '');
      try {
        const response = await viaCepService.viaCep(cepWithoutMask);

        const jsonViaCep = response.data;
        setLogradouro(jsonViaCep.logradouro);
        setLocalidade(jsonViaCep.localidade);
        setUf(jsonViaCep.uf);
        setBairro(jsonViaCep.bairro);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    listarOperadores();
    if (tipo === 'alteracao') {
      listarPontoVendaPorId();
    }
  }, []);

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=> { setAlert(null); if (alert.type === 'success') {window.history.back();} }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Origens &gt; Ponto de Venda &gt; Inclusão</h5> : (
                <h5>Origens &gt; Ponto de Venda &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Operador <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={operador} value={operador} onChange={(e) => setOperador(e.target.value)}>
                {operadores.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-evenly' }}>
              <label>
                <input
                  type="radio"
                  name="options"
                  value="cpf"
                  checked={selectCpfCnpj === 'cpf'}
                  onChange={() => checkCpfCnpj('cpf')}
                />
                CPF
              </label>
              <label>
                <input
                  type="radio"
                  name="options"
                  value="cnpj"
                  checked={selectCpfCnpj === 'cnpj'}
                  onChange={() => checkCpfCnpj('cnpj')}
                />
                CNPJ
              </label>
            </div>
            {selectCpfCnpj === 'cpf' ? (
              <div className="col-xl">
                <label>CPF <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpfCnpj} onChange={(e) => setCpfCnpj(e.target.value)} />
              </div>
            ) : (
              <div className="col-xl">
                <label>CNPJ <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="99.999.999/9999-99" type="text" className="form-control" value={cpfCnpj} onChange={(e) => setCpfCnpj(e.target.value)} />
              </div>
            )}
            <div className="col-xl">
              <label>Razão Social <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Porte</label>
              <select className="form-select" defaultValue={porte} value={porte} onChange={(e) => setPorte(e.target.value)}>
                {tipoPorte.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Situação Banco</label>
              <select className="form-select" defaultValue={situacaoBanco} value={situacaoBanco} onChange={(e) => setSituacaoBanco(e.target.value)}>
                {tipoSituacaoBanco.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Observação</label>
              <input type="text" className="form-control" value={observacao} onChange={(e) => setObservacao(e.target.value)} />
            </div>
          </div>
          <div className="row" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="col-xl">
              <label>
                <input
                  type="checkbox"
                  checked={pvBalcao}
                  onChange={checkboxPvBalcao}
                />
                Ponto de Venda Balcão
              </label>
            </div>

            <div className="col-xl">
              <label>
                <input
                  type="checkbox"
                  checked={bloqueiaCpf}
                  onChange={checkboxBloqueiaCpf}
                />
                Bloqueia CPF cadastrado no Não Perturbe
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Lista de E-mails (separados por ';') <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={emails} onChange={(e) => setEmails(e.target.value)} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            {loading ? <Spinner loading={loading} /> : false}
            <div className="col-md-2">
              <label>CEP <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="99.999-999" type="text" className="form-control" required
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                onBlur={(e) => viaCep(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label>Endereço <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label>Número <span style={{ color: 'red' }}>*</span></label>
              <input type="number" className="form-control" required value={numero} onChange={(e) => setNumero(e.target.value)} />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label>Bairro <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={bairro} onChange={(e) => setBairro(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Complemento</label>
              <input type="text" className="form-control" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Cidade <span style={{ color: 'red' }}>*</span></label>
              <input type="text" className="form-control" required value={localidade} onChange={(e) => setLocalidade(e.target.value)} />
            </div>
            <div className="col-md">
              <label>Estado <span style={{ color: 'red' }}>*</span></label>
              <InputMask mask="aa" type="text" required className="form-control" value={uf} onChange={(e) => setUf(e.target.value.toUpperCase())} />
            </div>
          </div>

          <hr className="my-4" />

          <div className="row">
            <div className="col-md">
              <label className="form-label">Telefone Comercial <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)9999-9999" type="text" className="form-control" value={telefoneComercial} onChange={(e) => setTelefoneComercial(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-md">
              <label className="form-label">Telefone Celular <span style={{ color: 'red' }}>*</span></label>
              <div className="row">
                <div className="col-md">
                  <InputMask required mask="(99)99999-9999" type="text" className="form-control" value={telefoneCelular} onChange={(e) => setTelefoneCelular(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
              <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Ponto de Venda' : 'Salvar Ponto de Venda'}</button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default PontoVendaInclusaoAlteracao;