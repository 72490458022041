import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../../shared/spinner';
import { faClose, faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropostaDTO } from '../../shared/dto/propostaDTO';
import PropostaService from '../../services/propostaService';
import PropostaEsteiraService from '../../services/propostaEsteiraService';
import CustomAlert from '../../shared/customAlert';

function RetornoFluxoComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [loading, setLoading] = useState(false);
  const [idProposta, setIdProposta] = useState('');
  const [exibeDetalhe, setExibeDetalhe] = useState(false);
  const [proposta, setProposta] = useState<PropostaDTO>();
  const [atividadeRetorno, setAtividadeRetorno] = useState('');
  const propostaService: PropostaService = new PropostaService();

  const propostaEsteiraService: PropostaEsteiraService = new PropostaEsteiraService();

  const listarPropostaEFluxoAnteriores = async () => {
    if (idProposta === '') return setAlert({ message: 'Por favor, informe o número da Proposta', type: 'warning' });
    setLoading(true);
    try {

      const response = await propostaService.listarPropostaEFluxoAnteriores(idProposta);

      const jsonResponse = response.data;
      
      setProposta(jsonResponse);
      setLoading(false);
      setExibeDetalhe(true);
      setAtividadeRetorno(jsonResponse.atividadesAnteriores[0].id?.toString()!);
      if (jsonResponse.isFinalizada) return setAlert({ message: 'Proposta Finalizada!', type: 'warning' });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const retornarFluxo = async (retornoEtapa: any) => {
    if (idProposta === '') return setAlert({ message: 'Por favor, informe o número da Proposta', type: 'warning' });
    setLoading(true);
    try {
      
      const response = await propostaEsteiraService.retornarFluxo(idProposta, retornoEtapa);
      const jsonResponse = response.data;
      setLoading(false);
      setExibeDetalhe(false);
      setIdProposta('');
      setAlert({ message: jsonResponse, type: 'success' });
    } catch (err: any) {
      setAlert({ message: err.response.data, type: 'error' });
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Esteira &gt; Proposta &gt; Retorno de Fluxo</h5>
        <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}> Voltar</Button>
      </div>

      <div className="row" style={{ marginTop: '10px' }}>
        <div className="col-md-2">
          <div className="form-group">
            <label>Proposta <span style={{ color: 'red' }}>*</span></label>
            <input type="text" className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'White' }}
              value={idProposta} onChange={(e) => { setIdProposta((e.target.value)); setExibeDetalhe(false); }} />
          </div>
        </div>
        <div className="col-md-2">
          <button className="input-group-btn"
            style={{
              backgroundColor: 'white', width: '30%', marginLeft: '10px', display: 'flex', alignItems: 'center',
              justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '54px', minWidth: '50px'
            }} onClick={listarPropostaEFluxoAnteriores}>
            <a>
              <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
            </a>
          </button>
        </div>
      </div>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={()=>setAlert(null)} />)}
      {loading ? <Spinner loading={loading} /> : (
        exibeDetalhe ? (
          <>
            <div style={{ marginTop: '10px' }}>
              <div className="row" style={{ marginTop: '1px' }}>
                <div className="col-md-5">
                  <span style={{ textAlign: 'left' }}>Nome Cliente</span>
                  <input type="text" value={proposta?.nomeCliente!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                </div>
              </div>
              <div className="row" style={{ marginTop: '1px' }}>
                <div className="col-md-1">
                  <span style={{ textAlign: 'left' }}>Situação</span>
                  <input type="text" value={proposta?.statusProposta!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                </div>
                <div className="col-md-4">
                  <span style={{ textAlign: 'left' }}>Atividade Atual</span>
                  <input type="text" value={proposta?.atividadeAtual!} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
                </div>
                {/* <div className="col-md-2">
                <span style={{ textAlign: 'left' }}>Data Cadastro</span>
                <input type="text" value={proposta?.dataCadastro ? format(new Date(proposta?.dataCadastro), 'dd/MM/yyyy') : ''} className="form-control form-control-sm brds " style={{ color: 'Black', backgroundColor: 'LightGrey' }} />
              </div> */}
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-md-5">
                  <span style={{ textAlign: 'left' }}>Atividade de Retorno (Atividade - Processo)</span>
                  <div className="col-md-5" style={{ display: 'flex', width: '100%', backgroundColor: 'white' }}>
                    <select className="form-select" style={{ backgroundColor: 'white', marginRight: '10px', width: '100%' }}
                      value={atividadeRetorno} onChange={(e) => setAtividadeRetorno(e.target.value)}>
                      {proposta?.atividadesAnteriores.map((item) => (
                        <option key={item.id} value={item.id!}>{item.atividade + ' - ' + item.processo}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {!proposta!.isFinalizada && proposta?.atividadesAnteriores && proposta?.atividadesAnteriores.length > 0 ?
              <div className="row" style={{ marginTop: '10px', height: '50px', maxWidth: '70%' }}>
                <div className="col-md-3">
                  <button className="input-group-btn"
                    style={{
                      width: '80%', display: 'flex', alignItems: 'center', backgroundColor: '#5a8e91', borderColor: '#5a8e91',
                      justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                    }} onClick={() => retornarFluxo(atividadeRetorno)}>
                    <a>
                      <FontAwesomeIcon icon={faSave} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                    </a>
                    <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Retornar Fluxo</strong>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="input-group-btn"
                    style={{
                      width: '80%', display: 'flex', alignItems: 'center', backgroundColor: 'red', borderColor: 'red',
                      justifyContent: 'center', border: '1px solid LightGrey', borderRadius: '5px', height: '100%', minWidth: '50px'
                    }} onClick={() => window.history.back()}>
                    <a>
                      <FontAwesomeIcon icon={faClose} style={{ color: 'White', fontSize: 'larger', marginTop: '5px' }} />
                    </a>
                    <strong style={{ color: 'White', marginLeft: '5px', fontSize: 'larger' }}> Cancelar</strong>
                  </button>
                </div>
              </div>
            : <></>}
          </>
        ) : null
      )}
    </div >
  );
}

export default RetornoFluxoComponent;
