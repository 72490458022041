import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { EsteiraDTO } from '../../../shared/dto/esteiraDTO';
import Spinner from '../../../shared/spinner';
import { EsteiraModelo } from '../../../shared/model/esteira_modelo';
import { Fases } from '../../../shared/constantes/fasesEsteira';
import { Processos } from '../../../shared/constantes/processosEsteira';
import { Situacoes } from '../../../shared/constantes/situacaoEsteira';
import EsteiraService from '../../../services/esteiraService';
import EsteiraModeloService from '../../../services/esteiraModeloService';
import CustomAlert from '../../../shared/customAlert';
import InputMask from 'react-input-mask';

const acoes = [
  { value: "", label: "" },
  { value: "A", label: "A" },
  { value: "P", label: "P" },
  { value: "F", label: "F" },
]

function EsteiraAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const location = useLocation();
  const { idEsteira, nomeEst } = location.state || {};
  const [loading, setLoading] = useState(false);
  const fases = Fases;
  const processos = Processos;
  const situacoes = Situacoes;

  const [nomeEsteira, setNomeEsteira] = useState(nomeEst);
  const [esteiraDTO, setEsteiraDTO] = useState<EsteiraDTO[]>([]);

  const esteiraService: EsteiraService = new EsteiraService();
  const esteiraModeloService: EsteiraModeloService = new EsteiraModeloService();

  const handleSubmitName = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let esteiraModelo: EsteiraModelo = new EsteiraModelo();
    esteiraModelo.nomeEsteira = nomeEsteira!;
    esteiraModelo.id = idEsteira!;

    try {
      await esteiraModeloService.incluirAlterarEsteiraModelo(esteiraModelo)
        .then(() => { setLoading(false); });

      setAlert({ message: 'Alteração do nome realizada com sucesso!', type: 'success' });
    } catch (err) {
      setLoading(false);
      setAlert({ message: 'Ocorreu um erro ao alterar nome esteira!', type: 'error' });
      console.error(err);
    }

  };

  const handleSalvarAtividade = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      await esteiraService.incluirAlterarEsteira(esteiraDTO);
      setLoading(false);
      setAlert({ message: 'Inclusão realizada com sucesso!', type: 'success' });
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    }
  };

  const listarEsteiraPorId = async () => {
    setLoading(true);
    try {
      const response = await esteiraService.listarEsteiraPorIdEsteiraModelo(idEsteira);
      setEsteiraDTO(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const adicionarFormularioEsteira = () => {
    let esteira: EsteiraDTO = new EsteiraDTO();
    esteira.idEsteiraModelo = parseInt(idEsteira!);
    if (esteiraDTO.length === 0) {
      esteira.numeroEtapa = 1;
    } else {
      esteira.numeroEtapa = esteiraDTO[esteiraDTO.length - 1].numeroEtapa + 1;
    }
    esteira.descricao = '';
    esteira.fase = 0;
    esteira.numeroEtapaAprovado = 0;
    esteira.numeroEtapaPendente = 0;
    esteira.numeroEtapaReprovado = 0;
    esteira.situacaoAprovado = '';
    esteira.situacaoPendente = '';
    esteira.situacaoReprovado = '';
    esteira.etapaProcesso = '';
    esteira.etapaAcao = '';
    esteira.etapaProcesso = '';
    esteira.etapaAcao = '';

    setEsteiraDTO([...esteiraDTO, esteira]);
  };

  const handleCampoChange = (index: number, campo: keyof EsteiraDTO, valor: string) => {
    console.log(esteiraDTO)
    const novasEsteiras = esteiraDTO.map((esteira, i) => (i === index ? { ...esteira, [campo]: valor } : esteira));
    setEsteiraDTO(novasEsteiras);
  };

  const removerTabela = (index: number) => {
    const novaEsteira = [...esteiraDTO];
    novaEsteira.splice(index, 1);
    setEsteiraDTO(novaEsteira);
  }

  useEffect(() => {
    listarEsteiraPorId();
  }, []);

  return (
    <div>
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      {loading ? <Spinner loading={loading} /> : (
        <>
          <div className="container">
            <form onSubmit={handleSubmitName}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <h5>Parâmetros Cartão &gt; Esteira &gt; Alteração</h5>
                  <Link to="/parametros-esteira">
                    <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }}> Voltar</Button>
                  </Link>
                </div>
                <hr className="my-4" />
              </div>
              <div className="row" style={{ display: 'flex' }}>
                <div className="col-md-1" style={{ maxWidth: '45%' }}>
                  <label>Código</label>
                  <text className="form-control" display={'none'}
                    style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>{idEsteira}
                  </text>
                </div>
                <div className="col-xl" style={{ maxWidth: '45%' }}>
                  <label>Nome <span style={{ color: 'red' }}>*</span></label>
                  <input type="text" required className="form-control" value={nomeEsteira} onChange={(e) => setNomeEsteira(e.target.value)} />
                </div>

                <div className="rmc-button" style={{ marginTop: '2%', maxWidth: '55%' }}>
                  <button type="submit" className="w-60 btn btn-primary " onClick={handleSubmitName}>
                    Atualizar Nome
                  </button>
                </div>
              </div>
              <hr className="my-4" />
            </form>
          </div>
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5 style={{ marginTop: '15px' }}>Atividades Esteira</h5>

              <button style={{ marginBottom: '12px' }} className="w-40 btn btn-lg" id="App-button-verde"
                onClick={() => adicionarFormularioEsteira()}>Nova Atividade
              </button>
            </div>

            {esteiraDTO.length > 0 ? (
              <div className="table-responsive m-md-0">
                <form>
                  <table border={3} className="table table-bordered table-striped" style={{ width: '100%', padding: '2px 4px', fontSize: '16px' }}>
                    <thead>
                      <tr className="GridHeaderStyle">
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small', width: '5%' }}>Nr</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Descrição Atividade</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Fase</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small', width: '5%' }}>At APR</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Sit APR</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small', width: '5%' }}>At PEN</th>
                        {/* <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Sit PEN</th> */}
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small', width: '5%' }}>At REP</th>
                        {/* <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Sit REP</th> */}
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Ação</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Processo</th>
                        <th scope="col" id="App-header-table" style={{ fontSize: 'small' }}>Remover</th>
                      </tr>
                    </thead>
                    <tbody>
                      {esteiraDTO.map((esteira, index) => (
                        <tr key={index} className="GridRowStyle" style={{ fontSize: 'small' }}>
                          <td>
                            <InputMask required maskChar={null} mask="99" type="text" className="form-control" value={esteira.numeroEtapa} onChange={(e) => handleCampoChange(index, 'numeroEtapa', e.target.value)} />
                          </td>
                          <td>
                            <input type="text" className="form-control" value={esteira.descricao} onChange={(e) => handleCampoChange(index, 'descricao', e.target.value)} />
                          </td>
                          <td>
                            <select className="form-select" value={esteira.fase} onChange={(e) => handleCampoChange(index, 'fase', e.target.value)} >
                              {fases.map(fase => (
                                <option value={fase.value}>{fase.label}</option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <InputMask required maskChar={null} mask="99" type="text" className="form-control" value={esteira.numeroEtapaAprovado} onChange={(e) => handleCampoChange(index, 'numeroEtapaAprovado', e.target.value)} />
                          </td>
                          <td>
                            <select className="form-select" value={esteira.situacaoAprovado} onChange={(e) => handleCampoChange(index, 'situacaoAprovado', e.target.value)}>
                              {situacoes.map((situacao, index) => (
                                <option key={index} value={situacao.value}>{situacao.label}</option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <InputMask required maskChar={null} mask="99" type="text" className="form-control" value={esteira.numeroEtapaPendente} onChange={(e) => handleCampoChange(index, 'numeroEtapaPendente', e.target.value)} />
                          </td>
                          {/* <td>
                            <select className="form-select" value={esteira.situacaoPendente} onChange={(e) => handleCampoChange(index, 'situacaoPendente', e.target.value)} >
                              {situacoes.map(situacao => (
                                <option value={situacao.value}>{situacao.label}</option>
                              ))}
                            </select>
                          </td> */}
                          <td>
                            <InputMask required maskChar={null} mask="99" type="text" className="form-control" value={esteira.numeroEtapaReprovado} onChange={(e) => handleCampoChange(index, 'numeroEtapaReprovado', e.target.value)} />
                          </td>
                          {/* <td>
                            <select className="form-select" value={esteira.situacaoReprovado} onChange={(e) => handleCampoChange(index, 'situacaoReprovado', e.target.value)} >
                              {situacoes.map(situacao => (
                                <option value={situacao.value}>{situacao.label}</option>
                              ))}
                            </select>
                          </td> */}
                          <td>
                            <select className="form-select" value={esteira.etapaAcao} onChange={(e) => handleCampoChange(index, 'etapaAcao', e.target.value)} >
                              {acoes.map(acao => (
                                <option value={acao.value}>{acao.label}</option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select className="form-select" value={esteira.etapaProcesso} onChange={(e) => handleCampoChange(index, 'etapaProcesso', e.target.value)} >
                              {processos.map(processo => (
                                <option value={processo.value}>{processo.label}</option>
                              ))}
                            </select>
                          </td>
                          <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ backgroundColor: '#dc3545', border: '1px solid #dc3545', color: 'white', cursor: 'pointer' }} onClick={() => removerTabela(index)}>X
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </form>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <button style={{ marginBottom: '12px' }} className="w-40 btn btn-lg" id="App-button-verde"
                    onClick={() => adicionarFormularioEsteira()}>Nova Atividade
                  </button>
                </div>

                <div>
                  <hr className="my-4" />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className="w-40 btn btn-primary btn-lg btn btn-success" onClick={handleSalvarAtividade}>Salvar Esteira</button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row" style={{ display: 'flex' }}>
                <div>
                  <text className="form-control" display={'none'}
                    style={{ color: "Black", backgroundColor: "LightGrey", textAlign: "left" }}>Nenhum resgistro encontrado!
                  </text>
                </div>
              </div>
            )}
          </div></>
      )}

    </div>
  );
}

export default EsteiraAlteracao;