import React from 'react';
import styled from 'styled-components';

interface CustomAlertModalProps {
  message: string;
  type: 'success' | 'warning' | 'error';
  onClose: () => void;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 0;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.div<{ type: 'success' | 'warning' | 'error' }>`
  background-color: ${({ type }) => {
    switch (type) {
      case 'success':
        return '#28a745'; 
      case 'warning':
        return '#d9a600'; 
      case 'error':
        return '#dc3545'; 
      default:
        return '#007bff';
    }
  }};
  color: white;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px 8px 0 0; 
`;

const Divider = styled.div`
  height: 2px;
  background-color: #e0e0e0;
`;

const ModalBody = styled.div`
  padding: 20px;
  background-color: white;
  color: black;
  font-size: 16px;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #3d6062;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  &:hover {
    background-color: #24393a;
  }
`;

const CustomAlertModal: React.FC<CustomAlertModalProps> = ({ message, type, onClose }) => {
  const getTitle = (type: 'success' | 'warning' | 'error') => {
    switch (type) {
      case 'success':
        return 'Sucesso';
      case 'warning':
        return 'Aviso';
      case 'error':
        return 'Erro';
      default:
        return '';
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader type={type}>{getTitle(type)}</ModalHeader>
        <Divider />
        <ModalBody>
          {message}
          <br />
          <CloseButton onClick={onClose}>OK</CloseButton>
        </ModalBody>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default CustomAlertModal;
