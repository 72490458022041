import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { GerenteDTO } from '../../../shared/dto/gerenteDTO';
import { ButtonAlterar, ButtonInativar, ButtonAtivar } from '../../../shared/buttons';
import SupervisorService from '../../../services/supervisorService';
import ConfirmModal from '../../../shared/customAlertConfirm';

function SupervisorComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gerente, setGerente] = useState<GerenteDTO[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);

  const supervisorService: SupervisorService = new SupervisorService();

  const listarTodosGerentes = async () => {
    try {
      setLoading(true);
      const responseList = await supervisorService.listarTodosGerentes();
      const jsonListGerente = responseList.data;

      setGerente(jsonListGerente);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarGerente = async (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
        await supervisorService.ativarInativarGerente(id);
        listarTodosGerentes();
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  useEffect(() => {
    listarTodosGerentes();
  }, []);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Origens &gt; Supervisor</h5>
          <Button
            onClick={() => navigate('/origens-supervisor/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Novo Supervisor
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <div>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table">Supervisor</th>
                    <th scope="col" id="App-header-table">CPF</th>
                    <th scope="col" id="App-header-table">Cidade</th>
                    <th scope="col" id="App-header-table">Telefone</th>
                    <th scope="col" id="App-header-table">E-mail</th>
                    <th scope="col" id="App-header-table">Promotora</th>
                    <th scope="col" id="App-header-table">Alteração</th>
                    <th scope="col" id="App-header-table">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {gerente.map((item) => (
                    <tr>
                      <td>{item.id} - {item.nome}</td>
                      <td>{item.cpf}</td>
                      <td>{item.cidade}</td>
                      <td>{item.telefoneComercial}</td>
                      <td>{item.email}</td>
                      <td>{item.promotora} - {item.nomePromotora}</td>
                      <td>
                        <Link to={`/origens-supervisor/alteracao`} state={{ id: item.id }}>
                          <ButtonAlterar />
                        </Link>
                      </td>
                      <td>
                        {item.ativo ?
                          <ButtonInativar onClick={() => ativarInativarGerente(item.id, item.ativo)} />
                          :
                          <ButtonAtivar onClick={() => ativarInativarGerente(item.id, item.ativo)} />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
            </div>
          </div>
        )}
      </div >
    </>
  );
}

export default SupervisorComponent;