import React from 'react';
import styled from 'styled-components';

interface CustomAlertModalProps {
  message: string;
  type: 'success' | 'warning' | 'error';
  onClose: () => void;
  imagemBase64?: string; // Adiciona a prop opcional para a imagem
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div<{ hasImage: boolean }>`
  background-color: white;
  padding: 0;
  border-radius: 8px;
  width: ${({ hasImage }) => (hasImage ? '80%' : '350px')};  
  max-width: 80%;  
  max-height: ${({ hasImage }) => (hasImage ? '80%' : 'auto')}; 
  text-align: center;
  overflow-y: ${({ hasImage }) => (hasImage ? 'auto' : 'unset')}; 
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column; /* Garante que o texto fique acima da imagem */
`;

const ModalHeader = styled.div<{ type: 'success' | 'warning' | 'error' }>`
  background-color: ${({ type }) => {
    switch (type) {
      case 'success':
        return '#28a745'; 
      case 'warning':
        return '#d9a600'; 
      case 'error':
        return '#dc3545'; 
      default:
        return '#007bff';
    }
  }};
  color: white;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px 8px 0 0; 
  position: relative;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o conteúdo */
  align-items: center; /* Centraliza verticalmente o conteúdo */
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #3d6062;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    background-color: #24393a;
  }
`;

const Divider = styled.div`
  height: 2px;
  background-color: #e0e0e0;
`;

const ModalBody = styled.div`
  padding: 20px;
  background-color: white;
  color: black;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinha o conteúdo no centro */
`;

const Image = styled.img`
  margin-top: 15px;
  max-width: 100%;
  max-height: 60vh;
  height: auto;
  border-radius: 8px;
`;

const CustomAlertModalComunicado: React.FC<CustomAlertModalProps> = ({ message, type, onClose, imagemBase64 }) => {
  const getTitle = (type: 'success' | 'warning' | 'error') => {
    switch (type) {
      case 'success':
        return 'Sucesso';
      case 'warning':
        return 'Aviso';
      case 'error':
        return 'Erro';
      default:
        return '';
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer hasImage={!!imagemBase64}>
        <ModalHeader type={type}>{getTitle(type)}
          <CloseButton onClick={onClose}>OK</CloseButton>
        </ModalHeader>
        <Divider />
        <ModalBody>
          <p>{message}</p>
          {imagemBase64 && <Image src={`data:image/png;base64,${imagemBase64}`} alt="Comunicado" />}
        </ModalBody>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default CustomAlertModalComunicado;
