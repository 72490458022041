import { TabelaIdadeRMC } from "../dto/tabelaIdadeRMC";

export class Empregador {
  public id!: number | string | null;
  public ativo!: boolean;
  public nome!: string;
  public cnpj!: string;
  public averbacao!: string;
  public parcelaMinima!: number;
  public qtdContratos!: string;
  public validarPor!: string;
  public diaCorte!: string;
  public diaRepasse!: string;
  public diaVencimento!: string;
  public ajustaDU!: string;
  public faixaCarencia!: string;
  public dataBaseSim!: Date;
  public dataPrimeiroVencimento!: Date;
  public tamanhoMatricula!: string;
  public codigoExternoBanco!: string;
  public codigoMotor!: string;
  public consultaMargemOnline!: boolean;
  public reservaMargemOnline!: boolean;
  public margemExclusiva!: boolean;
  public percentSegurancaRMC!: string;
  public diaCorteFatura!: string;
  public diaCortePrevia!: string;
  public tarifaEmissaoCartao!: string;
  public valorAnuidade!: string;
  public politicaIdade!: boolean;
  public tabelaIdade!: TabelaIdadeRMC[];
}