const TiposProposta = [
  { value: 'CARTAO_SACADO', label: 'Cartão Sacado' },
  { value: 'CARTAO_SEM_SAQUE', label: 'Cartão Sem Saque' },
  { value: 'SAQUE_COMPLEMENTAR', label: 'Saque Complementar' },
  { value: 'SAQUE_REFIN', label: 'Saque Refin' },
];

const getLabelTipoProp = (value: string) => {
  const tipoProposta = TiposProposta.find(prop => prop.value === value);
  return tipoProposta ? tipoProposta.label : '';
};

export { TiposProposta, getLabelTipoProp }