import React, { ChangeEvent, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import InputMask from 'react-input-mask';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../shared/spinner';
import { format, addMonths, addDays, parse, isValid } from 'date-fns';
import { TabelaIdadeRMC } from '../../../shared/dto/tabelaIdadeRMC';
import { Button } from 'react-bootstrap';
import { cnpj as cnpjValidacao } from 'cpf-cnpj-validator';
import { Empregador } from '../../../shared/model/empregador';
import EmpregadorService from '../../../services/empregadorService';
import CustomAlert from '../../../shared/customAlert';

function EmpregadorInclusaoAlteracao() {
  const tipoFaixaCarencia = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
  ];

  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();

  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [averbacao, setAverbacao] = useState('');
  const [parcelaMinima, setParcelaMinima] = useState('');
  const [qtdContratos, setQtdContratos] = useState('');
  const [validarPor, setValidarPor] = useState('');
  const [diaCorte, setDiaCorte] = useState('');
  const [diaRepasse, setDiaRepasse] = useState('');
  const [diaVencimento, setDiaVencimento] = useState('');
  const [ajustaDU, setAjustaDU] = useState('');
  const [faixaCarencia, setFaixaCarencia] = useState(tipoFaixaCarencia[0].value);
  const [dataBaseSim, setDataBaseSim] = useState('');
  const [dataPrimeiroVencimento, setDataPrimeiroVencimento] = useState('');
  const [tamanhoMatricula, setTamanhoMatricula] = useState('');
  const [codigoExternoBanco, setCodigoExternoBanco] = useState('');
  const [codigoMotor, setCodigoMotor] = useState('');
  const [consultaMargemOnline, setConsultaMargemOnline] = useState<boolean>(false);
  const [reservaMargemOnline, setReservaMargemOnline] = useState<boolean>(false);
  const [margemExclusiva, setMargemExclusiva] = useState<boolean>(false);
  const [percentSegurancaRMC, setPercentSegurancaRMC] = useState('');
  const [diaCorteFatura, setDiaCorteFatura] = useState('');
  const [diaCortePrevia, setDiaCortePrevia] = useState('');
  const [tarifaEmissaoCartao, setTarifaEmissaoCartao] = useState('');
  const [valorAnuidade, setValorAnuidade] = useState('');
  const [politicaIdade, setPoliticaIdade] = useState<boolean>(false)
  const [limiteIdade, setLimiteIdade] = useState('');
  const [fatorMultiplicadorRMC, setFatorMultiplicadorRMC] = useState('');
  const [valorMaximoLimite, setValorMaximoLimite] = useState('');

  const [tabelaIdade, setTabelaIdade] = useState<TabelaIdadeRMC[]>([]);

  const [loading, setLoading] = useState(false);

  const empregadorService: EmpregadorService = new EmpregadorService();

  const tipoAverbacao = [
    { value: 'Valor Saque', label: 'Valor Saque' },
    { value: 'Valor RMC', label: 'Valor RMC' },
  ];

  const tipoValidarPor = [
    { value: 'CPF + Matr', label: 'CPF + Matr' },
    { value: 'CPF', label: 'CPF' },
  ];

  const tipoAjustaDU = [
    //{ value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ];

  const setDatas = (data: any, diaCorte: number, diaVencimento: number, faixaCarencia: number) => {
    console.log(data.length);
    setDataBaseSim(data);
    if (data.length !== 10) return;
    try {
      const dtSimulacao = parse(data, "dd/MM/yyyy", new Date());

      if (!isValid(dtSimulacao)) {
        throw new Error('Invalid Date');
      }

      const dataCalculada = new Date(dtSimulacao);
      if (isNaN(diaCorte) || isNaN(diaVencimento)) {
        throw new Error('Dia de corte ou vencimento inválido');
      }
      if (dtSimulacao.getDate() > diaCorte) {
        dataCalculada.setMonth(dataCalculada.getMonth() + 2);
      } else {
        dataCalculada.setMonth(dataCalculada.getMonth() + 1);
      }

      dataCalculada.setMonth(dataCalculada.getMonth() + faixaCarencia);

      dataCalculada.setDate(diaVencimento);
      setDataPrimeiroVencimento(format(new Date(dataCalculada), 'dd/MM/yyyy'));

    } catch (err) {
      console.error('Erro ao calcular a data:', err);
    }
  };

  const adicionarDadoTabelaIdade = () => {
    if (!fatorMultiplicadorRMC || !limiteIdade || !valorMaximoLimite) {
      setAlert({ message: 'Preencha todos os campos da política de idade!', type: 'warning' });
      return;
    }
    if (tabelaIdade.some(item => item.idadeMaxima.toString() === limiteIdade)) {
      setAlert({ message: 'Já existe um registro com esta idade', type: 'error' });
      return;
    }
    let tabelaIdad: TabelaIdadeRMC = new TabelaIdadeRMC();
    tabelaIdad.fatorRMC = parseInt(fatorMultiplicadorRMC!);
    tabelaIdad.idadeMaxima = parseInt(limiteIdade!);
    tabelaIdad.valorMaximo = valorMaximoLimite!;
    setTabelaIdade([...tabelaIdade, tabelaIdad]);
    setFatorMultiplicadorRMC('');
    setLimiteIdade('');
    setValorMaximoLimite('');
  }

  const removerDadoTabelaIdade = (index: number) => {
    const newTabelaIdade = [...tabelaIdade];
    newTabelaIdade.splice(index, 1);
    setTabelaIdade(newTabelaIdade);
  }

  const listarEmpregadorPorId = async () => {
    setLoading(true);
    try {
      const response = await empregadorService.listarEmpregadorPorId(id);
      const jsonEmpregador = response.data;

      setNome(jsonEmpregador.nome);
      setCnpj(jsonEmpregador.cnpj);
      setAverbacao(jsonEmpregador.averbacao);
      setParcelaMinima(String(jsonEmpregador.parcelaMinima).replace('.', ','));
      setQtdContratos(jsonEmpregador.qtdContratos);
      setValidarPor(jsonEmpregador.validarPor);
      setDiaCorte(jsonEmpregador.diaCorte);
      setDiaRepasse(jsonEmpregador.diaRepasse);
      setDiaVencimento(jsonEmpregador.diaVencimento);
      setAjustaDU(jsonEmpregador.ajustaDU);
      setFaixaCarencia(jsonEmpregador.faixaCarencia);
      setTamanhoMatricula(jsonEmpregador.tamanhoMatricula);
      setCodigoExternoBanco(jsonEmpregador.codigoExternoBanco);
      setCodigoMotor(jsonEmpregador.codigoMotor);
      setConsultaMargemOnline(jsonEmpregador.consultaMargemOnline);
      setReservaMargemOnline(jsonEmpregador.reservaMargemOnline);
      setMargemExclusiva(jsonEmpregador.margemExclusiva);
      setPercentSegurancaRMC(jsonEmpregador.percentSegurancaRMC);
      setDiaCorteFatura(jsonEmpregador.diaCorteFatura);
      setDiaCortePrevia(jsonEmpregador.diaCortePrevia);
      setTarifaEmissaoCartao(jsonEmpregador.tarifaEmissaoCartao);
      setValorAnuidade(jsonEmpregador.valorAnuidade);
      setPoliticaIdade(jsonEmpregador.politicaIdade);
      setTabelaIdade(jsonEmpregador.tabelaIdade);
      setDatas(format(new Date(), 'dd/MM/yyyy'), jsonEmpregador.diaCorte, jsonEmpregador.diaVencimento, jsonEmpregador.faixaCarencia);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const limparCampos = () => {
    setNome('');
    setCnpj('');
    setAverbacao('');
    setParcelaMinima('');
    setQtdContratos('');
    setValidarPor('');
    setDiaCorte('');
    setDiaRepasse('');
    setDiaVencimento('');
    setAjustaDU('');
    setFaixaCarencia('');
    setDatas(format(new Date(), 'dd/MM/yyyy'), 1, 1, 0);
    setTamanhoMatricula('');
    setCodigoExternoBanco('');
    setCodigoMotor('');
    setConsultaMargemOnline(false);
    setReservaMargemOnline(false);
    setMargemExclusiva(false);
    setPercentSegurancaRMC('');
    setDiaCorteFatura('');
    setDiaCortePrevia('');
    setTarifaEmissaoCartao('');
    setValorAnuidade('');
    setPoliticaIdade(false);
    setLimiteIdade('');
    setFatorMultiplicadorRMC('');
    setValorMaximoLimite('');
    setTabelaIdade([]);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!cnpjValidacao.isValid(cnpj) && cnpj !== '') {
      setAlert({ message: 'CNPJ Inválido', type: 'warning' });
      return;
    }
    if ((tabelaIdade == null || tabelaIdade.length < 1) && politicaIdade == false) {
      setAlert({ message: 'Não existe Faixas de Idade Cadastrada!', type: 'error' });
      return;
    } 
    if (parseFloat(parcelaMinima.replace(',', '.')) < 1.00) {
      setAlert({ message: 'Parcela Minima deve ser no minimo 1,00', type: 'error' });
      return;
    }
    setLoading(true);
    let empregador: Empregador = new Empregador();
    empregador.id = id;
    empregador.nome = nome;
    empregador.cnpj = cnpj;
    empregador.averbacao = averbacao;
    empregador.parcelaMinima = parseFloat(parcelaMinima.replace(',', '.'));
    empregador.qtdContratos = qtdContratos;
    empregador.validarPor = validarPor;
    empregador.diaCorte = diaCorte;
    empregador.diaRepasse = diaRepasse;
    empregador.diaVencimento = diaVencimento;
    empregador.ajustaDU = ajustaDU;
    empregador.faixaCarencia = faixaCarencia;
    empregador.tamanhoMatricula = tamanhoMatricula;
    empregador.codigoExternoBanco = codigoExternoBanco;
    empregador.codigoMotor = codigoMotor;
    empregador.consultaMargemOnline = consultaMargemOnline;
    empregador.reservaMargemOnline = reservaMargemOnline;
    empregador.margemExclusiva = margemExclusiva;
    empregador.percentSegurancaRMC = percentSegurancaRMC;
    empregador.diaCorteFatura = diaCorteFatura;
    empregador.diaCortePrevia = diaCortePrevia;
    empregador.tarifaEmissaoCartao = tarifaEmissaoCartao;
    empregador.valorAnuidade = valorAnuidade;
    empregador.politicaIdade = politicaIdade;
    empregador.tabelaIdade = tabelaIdade;

    try {

      await empregadorService.incluirAlterarEmpregador(empregador).then(() => setLoading(false));
      setAlert({ message: 'Inclusão realizada com sucesso!', type: 'success' });
      await sleep(2000);

      window.location.href = '/parametros-empregador';
    } catch (err) {
      setLoading(false);
      setAlert({ message: 'Ocorreu um erro ao criar empregador!', type: 'error' });
      console.error(err);
    }

  };

  const formatarValorMonetario = (valor: any) => {
    if (!valor) return '0,00';
    const valorComCentavos = valor.padStart(3, '0');
    const reais = valorComCentavos.slice(0, -2);
    const centavos = valorComCentavos.slice(-2);
    return `${parseInt(reais, 10)},${centavos}`;
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valorDigitado = e.target.value.replace(/\D/g, '');
    const valorFormatado = formatarValorMonetario(valorDigitado);
    setParcelaMinima(valorFormatado);
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarEmpregadorPorId();
    } else {
      setDatas(format(new Date(), 'dd/MM/yyyy'), 1, 1, parseInt(faixaCarencia));
    }
  }, []);

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Parâmetros Cartão &gt; Empregador &gt; Inclusão</h5> : (
                <h5>Parâmetros Cartão &gt; Empregador &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row">
            {tipo === 'alteracao' ? (
              <div className="col-md-1">
                <label>Código</label>
                <input style={{ backgroundColor: 'LightGrey' }} type="text" required className="form-control" value={id} readOnly />
              </div>
            ) : null}
            <div className="col-xl">
              <label>Nome <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>CNPJ</label>
              <InputMask mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="col-xl">
              <label>Tipo Averbação <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={averbacao[0]} value={averbacao} onChange={(e) => setAverbacao(e.target.value)}>
                {tipoAverbacao.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Parcela Mínima <span style={{ color: 'red' }}>*</span></label>
              <input type="text" required className="form-control" value={parcelaMinima} onChange={handleChange} />
            </div>
            <div className="col-xl">
              <label>QTD Contratos <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={qtdContratos} onChange={(e) => setQtdContratos((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Validar Por: <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={validarPor[0]} value={validarPor} onChange={(e) => setValidarPor(e.target.value)}>
                {tipoValidarPor.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Dia de Corte <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={diaCorte} onChange={(e) => { setDiaCorte((e.target.value).replace(/_/g, "")); setDatas(dataBaseSim, parseInt((e.target.value).replace(/_/g, "")), parseInt(diaVencimento), parseInt(faixaCarencia)) }} />
            </div>
            <div className="col-xl">
              <label>Dia de Repasse <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={diaRepasse} onChange={(e) => setDiaRepasse((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia Vencimento <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={diaVencimento} onChange={(e) => { setDiaVencimento((e.target.value).replace(/_/g, "")); setDatas(dataBaseSim, parseInt(diaCorte), parseInt((e.target.value).replace(/_/g, "")), parseInt(faixaCarencia)) }} />
            </div>
            <div className="col-xl">
              <label>Ajusta DU? <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={ajustaDU[0]} value={ajustaDU} onChange={(e) => setAjustaDU(e.target.value)} aria-readonly>
                {tipoAjustaDU.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xl">
              <label>Faixa de Carência <span style={{ color: 'red' }}>*</span></label>
              <select className="form-select" defaultValue={faixaCarencia} value={faixaCarencia} onChange={(e) => { setFaixaCarencia(e.target.value); setDatas(dataBaseSim, parseInt(diaCorte), parseInt(diaVencimento), parseInt(e.target.value)) }}>
                {tipoFaixaCarencia.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Data Base Simulação</label>
              <InputMask mask="99/99/9999" className="form-control" type="text" value={dataBaseSim} onChange={(e) => setDatas((e.target.value).replace(/_/g, ""), parseInt(diaCorte), parseInt(diaVencimento), parseInt(faixaCarencia))} />
            </div>
            <div className="col-xl">
              <label>Data 1º Vcto. </label>
              <text style={{ backgroundColor: 'LightGrey' }} className="form-control" display={'none'}>{dataPrimeiroVencimento}</text>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Tamanho Matrícula <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={tamanhoMatricula} onChange={(e) => setTamanhoMatricula((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Código Externo Banco </label>
              <InputMask maskChar={null} mask="9999" type="text" className="form-control" value={codigoExternoBanco} onChange={(e) => setCodigoExternoBanco((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Código Motor </label>
              <input type="text" className="form-control" value={codigoMotor} onChange={(e) => setCodigoMotor(e.target.value)} />
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-evenly', marginBottom: '15px' }}>
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={consultaMargemOnline} onChange={(consultaMargemOnline) => setConsultaMargemOnline(consultaMargemOnline.target.checked)} />
                Possui Consulta de Margem Online
              </label>
            </div>
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={reservaMargemOnline} onChange={(reservaMargemOnline) => setReservaMargemOnline(reservaMargemOnline.target.checked)} />
                Possui Reserva de Margem Online
              </label>
            </div>
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={margemExclusiva} onChange={(margemExclusiva) => setMargemExclusiva(margemExclusiva.target.checked)} />
                Margem Exclusiva
              </label>
            </div>
          </div>

          <h5>Parâmetros Cartão Consignado</h5>

          <div className="row" style={{ marginBottom: '15px' }}>
            <div className="col-xl">
              <label>% Segurança RMC <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="9999999999" type="text" required className="form-control" value={percentSegurancaRMC} onChange={(e) => setPercentSegurancaRMC((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia Corte Fatura <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={diaCorteFatura} onChange={(e) => setDiaCorteFatura((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Dia Corte Prévia <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" required className="form-control" value={diaCortePrevia} onChange={(e) => setDiaCortePrevia((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Tarifa Emissão Cartão</label>
              <InputMask maskChar={null} mask="9999999999" type="text" className="form-control" value={tarifaEmissaoCartao} onChange={(e) => setTarifaEmissaoCartao((e.target.value).replace(/_/g, ""))} />
            </div>
            <div className="col-xl">
              <label>Valor Anuidade</label>
              <InputMask maskChar={null} mask="9999999999" type="text" className="form-control" value={valorAnuidade} onChange={(e) => setValorAnuidade((e.target.value).replace(/_/g, ""))} />
            </div>
          </div>

          <h5>Política de Idade</h5>
          <div className="row">
            <div className="col-xl">
              <label>
                <input type="checkbox" checked={politicaIdade} onChange={(politicaIdade) => { setPoliticaIdade(politicaIdade.target.checked); setTabelaIdade([]); }} />
                Utilizar Política de Idade do Cadastro de Produtos
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-xl">
              <label>Limite de Idade <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="99" type="text" className="form-control" value={limiteIdade} onChange={(e) => setLimiteIdade((e.target.value).replace(/_/g, ""))}
                disabled={politicaIdade} style={{ backgroundColor: politicaIdade ? 'LightGrey' : 'white' }} />
            </div>
            <div className="col-xl">
              <label>Fator Multiplicador RMC <span style={{ color: 'red' }}>*</span></label>
              <InputMask maskChar={null} mask="9999999999" type="text" className="form-control" value={fatorMultiplicadorRMC} onChange={(e) => setFatorMultiplicadorRMC((e.target.value).replace(/_/g, ""))}
                disabled={politicaIdade} style={{ backgroundColor: politicaIdade ? 'LightGrey' : 'white' }} />
            </div>
            <div className="col-xl">
              <label>Valor Máximo de Limite <span style={{ color: 'red' }}>*</span></label>
              <input type="number" className="form-control" value={valorMaximoLimite} onChange={(e) => setValorMaximoLimite(e.target.value)}
                disabled={politicaIdade} style={{ backgroundColor: politicaIdade ? 'LightGrey' : 'white' }} />
            </div>
            <div className="col-xl">
              <Button style={{ backgroundColor: '#36D7B7', border: '1px solid #36D7B7', color: 'white', cursor: 'pointer', textAlign: 'center', marginTop: '20px' }}
                onClick={() => adicionarDadoTabelaIdade()}>+
              </Button>
            </div>
          </div>

          {tabelaIdade.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" id="App-header-table">Idade Máxima</th>
                    <th scope="col" id="App-header-table">Fator RMC</th>
                    <th scope="col" id="App-header-table">Valor Máximo Limite</th>
                    <th scope="col" id="App-header-table">Excluir</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelaIdade.map((item, index) => (
                    <tr>
                      <td>{item.idadeMaxima}</td>
                      <td>{item.fatorRMC}</td>
                      <td>R$ {item.valorMaximo}</td>
                      <td>
                        <Button style={{
                          backgroundColor: '#dc3545',
                          border: '1px solid #dc3545',
                          color: 'white',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }} onClick={() => removerDadoTabelaIdade(index)}>X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : false}
          <div>
            <hr className="my-4" />
            <div>
              <hr className="my-4" />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={limparCampos} className="w-40 btn btn-lg" id="App-button-verde">Limpar Campos</button>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Empregador' : 'Salvar Empregador'}</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default EmpregadorInclusaoAlteracao;