import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonAlterar } from '../../shared/buttons';
import Spinner from '../../shared/spinner';
import { Comunicado } from '../../shared/model/comunicado';
import { format } from 'date-fns';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComunicadoService from '../../services/comunicadoService';

function ComunicadosComponent() {
  const [loading, setLoading] = useState(true);
  const [comunicados, setComunicados] = useState<Comunicado[]>([]);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  const comunicadoService: ComunicadoService = new ComunicadoService();

  const listarTodosComunicados = async () => {
    try {
      setLoading(true);
      const responseList = await comunicadoService.listarTodosComunicados();
      console.log(responseList.data);
      setComunicados(responseList.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    listarTodosComunicados();
  }, [refresh]);

  return (
    <>
      <div className="position-relative m-md-3">
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Comunicados</h5>
          <Button
            onClick={() => navigate('/comunicados/inclusao', { state: { id: null } })}
            style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
          >
            + Novo Comunicado
          </Button>
        </div>
        {loading ? <Spinner loading={loading} /> : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table">Código</th>
                  <th scope="col" id="App-header-table">Nome</th>
                  <th scope="col" id="App-header-table">Promotora</th>
                  <th scope="col" id="App-header-table">Data Início</th>
                  <th scope="col" id="App-header-table">Data Fim</th>
                  <th scope="col" id="App-header-table">Visualizar</th>
                  <th scope="col" id="App-header-table">Alteração</th>
                </tr>
              </thead>
              <tbody>
                {comunicados.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.nomeComunicado}</td>
                    <td>{item.idPromotora}</td>
                    <td>{item.dtInicio ? format(new Date(item.dtInicio), 'dd/MM/yyyy') : ''}</td>
                    <td>{item.dtFim ? format(new Date(item.dtFim), 'dd/MM/yyyy') : ''}</td>
                    <td>
                      <Button style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} 
                        onClick={() => navigate('/comunicados-visualizacao', { state: { id: item.id } })}>
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </td>
                    <td>
                      <Link to={`/comunicados/alteracao`} state={{ id: item.id }}>
                        <ButtonAlterar />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default ComunicadosComponent;